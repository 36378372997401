import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { companyApi } from "../../assets/apis";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "../../redux-store/Company";

let successMessage = "Mail Permission Changed Successfully";

const MailPermissions = () => {
  const { companyData } = useSelector((state) => state.Company);

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [mailPermissions, setMailPermissions] = useState({
    sales_invoice: false,
    quotation: false,
    bill_of_supply: false,
    delivery_challan: false,
    Advance_receipt: false,
  });

  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    setMailPermissions((prevPermissions) => ({
      ...prevPermissions,
      [name]: checked,
    }));
  };

  const handleCloseSuccessMessage = () => {
    setSuccess(false);
  };

  const getCompany = async () => {
    try {
      const result = await axios.get(companyApi);
      const response = result.data;
      dispatch(setCompanyData(response.company));
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAllFalse = Object.values(mailPermissions).some(
      (permission) => permission
    );
    let obj = { ...mailPermissions };
    if (!isAllFalse) {
      obj = { ...obj, sales_invoice: true };
      setMailPermissions(obj);
    }
    const formdata = new FormData();
    formdata.append("companyDetails", JSON.stringify({ mailPermissions: obj }));
    try {
      await axios.put(companyApi, formdata);
      toast.success("Mail Permission Changed Successfully");
      getCompany();
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    }
  };

  const memoizedData = useMemo(() => {
    if (companyData?.mailPermissions) {
      const obj = companyData.mailPermissions;
      return obj;
    }
    return {};
  }, [companyData]);

  useEffect(() => {
    setMailPermissions((prevPermissions) => ({
      ...prevPermissions,
      ...memoizedData,
    }));
  }, [memoizedData]);

  useEffect(() => {
    const timeout = setTimeout(handleCloseSuccessMessage, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline">
              <h3 className="ukhd mb-3">Select Mail Sending Permissions</h3>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      {success && (
                        <div
                          className="alert alert-success mb-5"
                          role="alert"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {successMessage}
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleCloseSuccessMessage}
                          ></button>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-3">
                          <div className="row">
                            <div className="col">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="sales_invoice"
                                  name="sales_invoice"
                                  checked={mailPermissions.sales_invoice}
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sales_invoice"
                                >
                                  Sales Invoice
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="quotation"
                                  name="quotation"
                                  checked={mailPermissions.quotation}
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="quotation"
                                >
                                  Quotation
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* <div className="row mt-3">
                            <div className="col">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="bill_of_supply"
                                  name="bill_of_supply"
                                  checked={mailPermissions.bill_of_supply}
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="bill_of_supply"
                                >
                                  Bill Of Supply
                                </label>
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="row mt-3">
                            <div className="col">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="delivery_challan"
                                  name="delivery_challan"
                                  checked={mailPermissions.delivery_challan}
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="delivery_challan"
                                >
                                  Delivery Challan
                                </label>
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="row mt-3">
                            <div className="col">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="Advance_receipt"
                                  name="Advance_receipt"
                                  checked={mailPermissions.Advance_receipt}
                                  onChange={handleInputChange}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="Advance_receipt"
                                >
                                  Advance Receipt
                                </label>
                              </div>
                            </div>
                          </div> */}
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm mt-3 px-3 py-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailPermissions;
