import React from "react";
import { ToWords } from "to-words";

import { useSelector } from "react-redux";
import {
  dateFormateDDMMYYYY,
  parseFloatWithFixedValue,
} from "../../../utils/helpers";
const Thermal = ({ printRef }) => {
  const { QuotationData } = useSelector((store) => store.Invoice);
  const { companyData } = useSelector((state) => state.Company);

  const checkout_details = QuotationData?.checkout_details || {};
  const isBillAndShipAddressSame = checkout_details?.isBillAndShipAddressSame;
  const ship_to_details = {
    shipping_display_name: isBillAndShipAddressSame
      ? checkout_details?.display_name
      : checkout_details?.shipping_display_name,
    shipping_address: isBillAndShipAddressSame
      ? checkout_details?.billing_address
      : checkout_details?.shipping_address,
    shipping_city: isBillAndShipAddressSame
      ? checkout_details?.billing_city
      : checkout_details?.shipping_city,
    shipping_state: isBillAndShipAddressSame
      ? checkout_details?.billing_state
      : checkout_details?.shipping_state,
    shipping_pin_code: isBillAndShipAddressSame
      ? checkout_details?.billing_PIN_Code
      : checkout_details?.shipping_pin_code,
    shipping_country: isBillAndShipAddressSame
      ? checkout_details?.billing_country
      : checkout_details?.shipping_country,
    shipping_phone: isBillAndShipAddressSame
      ? checkout_details?.phone
      : checkout_details?.shipping_phone,
    shipping_email: isBillAndShipAddressSame
      ? checkout_details?.email
      : checkout_details?.shipping_email,
  };

  return (
    <div className="p-5">
      <div className="print_thermal_container" ref={printRef}>
        <div className="thermal_first-section">
          <span className="title">Quotation Invoice</span>
          <span className="title">
            {companyData?.quotation_prefix
              ? `${companyData?.quotation_prefix}${QuotationData?.quotation}`
              : `QUA-${QuotationData?.quotation}`}
          </span>
          <div>
            <span className="title">Date : </span>
            <span>{dateFormateDDMMYYYY(QuotationData?.date)}</span>
          </div>
        </div>
        <hr />
        <div className="thermal_second_section">
          <div className="thermal_second_section_child  pe-3">
            <span className="title">{companyData?.name}</span>
            <span className="title_body">
              {companyData?.address}
              {", "}
              {companyData?.pin}
            </span>
            {/* <span className="title_body">
              {companyData?.state} {companyData?.pin}
            </span> */}
            <span className="title_body">
              <span>Phone : </span>
              <span>{companyData?.phone || ""}</span>
            </span>
            <span className="title_body">
              <span>Email : </span>
              <span>{companyData?.email || ""}</span>
            </span>
            {companyData?.website ? (
              <span className="title_body">
                <span>Website : </span>
                <span>{companyData?.website || ""}</span>
              </span>
            ) : (
              ""
            )}
            <span className="title">
              <span>GSTIN : </span>
              <span>{companyData?.gst}</span>
            </span>
          </div>
          <div className="thermal_second_section_child  pe-3">
            <span className="title ">Ship to</span>
            <span className="title">
              {ship_to_details?.shipping_display_name}
            </span>
            <span className="title_body">
              {ship_to_details?.shipping_address}
            </span>
            <span className="title_body">
              {ship_to_details?.shipping_city} {ship_to_details?.shipping_state}{" "}
              {ship_to_details?.shipping_pin_code}
            </span>
            <span className="title_body">
              {ship_to_details?.shipping_country}
            </span>
            <span className="title_body">
              <span>Phone : </span>
              <span>{ship_to_details?.shipping_phone || ""}</span>
            </span>
            {ship_to_details?.shipping_email ? (
              <span className="title_body">
                <span>Email : </span>
                <span>{ship_to_details?.shipping_email || ""}</span>
              </span>
            ) : (
              ""
            )}
            {checkout_details?.GSTIN ? (
              <span className="title">
                <span>GSTIN : </span>
                <span>{checkout_details?.GSTIN || ""}</span>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="thermal_third_section">
          <table>
            <thead>
              <tr className="title">
                <th className="text-align-left">Product</th>
                <th>Qty</th>
                <th>Rate</th>

                {QuotationData?.invoiceType === "item_wise_discount_and_tax" ? (
                  <>
                    <th>Disc.</th>
                    <th>Tax</th>
                  </>
                ) : (
                  ""
                )}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {QuotationData?.all_products.map((product, index) => (
                <tr className="title-body" key={index}>
                  <td className="text-align-left">{product?.product?.name}</td>
                  <td>{product?.quantity}</td>
                  <td>{product?.price}</td>
                  {QuotationData.invoiceType ===
                  "item_wise_discount_and_tax" ? (
                    <>
                      {" "}
                      <td>
                        {parseFloatWithFixedValue(product?.discount || 0)}
                      </td>
                      <td>{parseFloatWithFixedValue(product?.itemTax)}</td>
                    </>
                  ) : (
                    ""
                  )}
                  <td>{product?.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table>
            <thead>
              <tr className="title border-top-none">
                <td></td>
                <th className="padding-right">Amount</th>
                <th>
                  ₹&nbsp;
                  {QuotationData?.all_products?.reduce(
                    (sum, product) => sum + product.amount,
                    0
                  ) || 0}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="title-body border-bottom-none ">
                <td></td>

                {/* <td className="title padding-right">
                Discount({QuotationData?.other_charges?.discount || 0}%)
              </td> */}
                {/* <td className="title">₹&nbsp;{QuotationData?.discount || 0}</td> */}
              </tr>
              {QuotationData?.other_charges?.shipping_charges && (
                <tr className="title-body border-bottom-none">
                  <td></td>
                  <td className="title padding-right">Shipping Charges</td>
                  <td className="title">
                    ₹&nbsp;{QuotationData?.other_charges?.shipping_charges}
                  </td>
                </tr>
              )}
              {QuotationData?.other_charges?.e_commerce_gst && (
                <tr className="title-body border-bottom-none">
                  <td></td>
                  <td className="title padding-right">E Commerce Gst</td>
                  <td className="title">
                    ₹&nbsp;{QuotationData?.other_charges?.e_commerce_gst}
                  </td>
                </tr>
              )}
              {QuotationData?.other_charges?.transport && (
                <tr className="title-body border-bottom-none">
                  <td></td>
                  <td className="title padding-right">Transport</td>
                  <td className="title">
                    ₹&nbsp;{QuotationData?.other_charges?.transport}
                  </td>
                </tr>
              )}

              <tr className="title-body border-bottom-none">
                <td></td>
                <td className="title padding-right">Total Amount</td>
                <td className="title">₹&nbsp;{QuotationData?.total || 0}</td>
              </tr>
              {/* <tr className="title-body border-bottom-none">
              <td></td>
              <td className="title padding-right">Paid Amount</td>
              <td className="title">₹&nbsp;{QuotationData?.paid_amount || 0}</td>
            </tr> */}
              {/* <tr className="title-body border-bottom-none">
              <td></td>
              <td className="title padding-right">Due Amount</td>
              <td className="title">₹&nbsp;{QuotationData?.balance||0}</td>
            </tr> */}
            </tbody>
          </table>
        </div>
        <hr className="hr" />
        <div className="thermal_fourth_section">
          <span className="title">
            Certified that the particular given are true and correct
          </span>
        </div>
        <hr />
        <div className="thermal_fifth_section">
          <div className="title">
            {companyData?.is_common_seal
              ? companyData?.common_seal && (
                  <span>
                    <img src={companyData?.common_seal} alt="" width={80} />
                  </span>
                )
              : ""}

            <span>Common Seal</span>
          </div>
          <div className="title">
            <span>For &nbsp;{companyData?.name}&nbsp;</span>
            <div>
              {companyData?.is_sign
                ? companyData?.sign && (
                    <span>
                      <img src={companyData?.sign} alt="" width={80} />
                    </span>
                  )
                : ""}
              <span className="text-align-center">Authorised Signatory</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thermal;
