export const unitOfMeasurement = [
  "Piece",
  "Boxes",
  "Bunches",
  "Bale",
  "Buckles",
  "Billion of Unit",
  "Bags",
  "Bottles",
  "Bundles",
  "Cans",
  "Cubic Meters",
  "Cubic Centimeters",
  "Centimeters",
  "Cartons",
  "Drumns",
  "Dozens",
  "Gross",
  "Great Gross",
  "Gross Yard",
  "Gram",
  "Hours",
  "Inches",
  "Kilogram",
  "Kiloliter",
  "Kilometers",
  "Liters",
  "Meter",
  "Mililiter",
  "Metric Ton",
  "Numbers",
  "Packs",
  "Pairs",
  "Quintal",
  "Rolls",
  "Set",
  "Ton",
  "Square Feet",
  "Square Meter",
  "Square Yard",
  "Tablets",
  "Ten Gross",
  "Thousands",
  "Tubes",
  "US Gallons",
  "Units",
  "Yards",
];
