import React, { useEffect, useState } from "react";
import BasicPlan from "../../plans/BasicPlan/BasicPlan";
import { Navigate, useNavigate } from "react-router-dom";
import PlanHeroSection from "../../assets/images/logos/PlanHeroSection.jpg";
import sencodBackground from "../../assets/images/logos/sencodBackground.jpg";
import { useDispatch, useSelector } from "react-redux";
import { expiryDetails } from "../../utils/helpers";
import Spinner from "../../spinner/first_spinner/Spinner";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../redux-store/sidebarActiveList";

const Plan = () => {
  const navigate = useNavigate();
  const { companyData } = useSelector((state) => state.Company);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (companyData && !isEmpty(companyData)) {
      if (expiryDetails(companyData?.expired)?.isShow) {
        setIsPageLoading(false);
      } else {
        navigate("/");
      }
    }
  }, [companyData]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: null }));
    dispatch(setSiderBarActiveLink({ link: null }));
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {isPageLoading ? (
          <SecondSpinner />
        ) : (
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-rounded">
                <div
                  className="card-body"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #FFC371, #FF5F6D)",
                    backgroundSize: "cover", // Makes the image cover the entire div
                    backgroundRepeat: "no-repeat",
                    borderRadius: "12px",
                  }}
                >
                  <div className="col-lg-12" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => navigate("/")}
                    ></button>
                  </div>
                  <div className="col-lg-12"></div>
                  <BasicPlan />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Plan;
function isEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}
