import React from "react";
import { Pagination } from "@mui/material";

const CustomPagination = ({
  count,
  page,
  length,
  onChange,
  perPage,
  currentDataLength,
}) => {
  return (
    <div
      className="w-100 d-flex"
      style={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <span>
        Showing {perPage * (page - 1) + 1} to{" "}
        {perPage * (page - 1) + currentDataLength} of {length} entries
      </span>
      <Pagination
        count={count}
        size="medium"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={onChange}
      />
    </div>
  );
};

export default CustomPagination;
