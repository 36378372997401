import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { state } from "../../assets/StateList";
import { countryList } from "../../assets/countryList";
import { companyApi } from "../../assets/apis";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setCompanyData } from "../../redux-store/Company";
import { selectCustomStyle } from "../../assets/selectCustomStyle";
import NoImage from "../../assets/images/logos/no-pictures.png";
function ManageCompany() {
  const { companyData } = useSelector((state) => state.Company);

  const navigate = useNavigate();
  const [company, setCompany] = useState({});
  const [file, setFile] = useState({});
  const [check, setCheck] = useState({});
  const [logo, setLogo] = useState({ show: null, send: null });
  const [sign, setSign] = useState({ show: null, send: null });
  const [commonSeal, setCommonSeal] = useState({ show: null, send: null });
  const dispatch = useDispatch();

  const isCountryIndia = company.country === "India";

  useMemo(() => {
    if (companyData) {
      setCompany({
        address: companyData.address,
        cin_no: companyData.cin_no,
        country: companyData.country,
        state: companyData.state,
        city: companyData.city,
        dl_no: companyData.dl_no,
        email: companyData.email,
        gst: companyData.gst,
        name: companyData.name,
        pan: companyData.pan,
        phone: companyData.phone,
        pin: companyData.pin,
        service_tax: companyData.service_tax,
        tin: companyData.tin,
        website: companyData.website,
        upi: companyData.upi,
        sales_inv_prefix: companyData.sales_inv_prefix,
        purchase_inv_prefix: companyData.purchase_inv_prefix,
        additional_detail: companyData.additional_detail,
        running_out_limit: companyData.running_out_limit,
        quotation_prefix: companyData.quotation_prefix,
        bussiness_type: companyData.bussiness_type,
        e_commerce_gst: companyData.e_commerce_gst,
      });
      setLogo({ show: companyData.logo, send: null });
      setSign({ show: companyData.sign, send: null });
      setCommonSeal({ show: companyData.common_seal, send: null });
      setCheck({
        is_bank_detail: companyData.is_bank_detail,
        is_common_seal: companyData.is_common_seal,
        is_logo: companyData.is_logo,
        is_sign: companyData.is_sign,
      });
    }
  }, [companyData]);

  const getCompany = async () => {
    try {
      const result = await axios.get(companyApi);
      const response = result.data;
      dispatch(setCompanyData(response.company));
    } catch (error) {}
  };

  const manageSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "name",
      "email",
      "phone",
      "address",
      "country",
      "state",
      "city",
      "pin",
    ];
    const missingFields = requiredFields.filter((field) => !company[field]);

    if (missingFields.length > 0) {
      // Display an alert or handle the missing fields in your preferred way
      toast.error(
        `Please fill in the required fields: ${missingFields.join(", ")}`
      );
      return;
    }

    const formData = new FormData();

    logo.send && formData.append("logo", logo.send);
    commonSeal.send && formData.append("common_seal", commonSeal.send);
    sign.send && formData.append("sign", sign.send);
    // If companyDetails is an object, stringify it
    formData.append(
      "companyDetails",
      JSON.stringify({
        ...company,
        ...check,
      })
    );

    try {
      const response = await axios.put(companyApi, formData);
      toast.success("Company Details updated successfully");
      getCompany();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const handleChange = (props, filename) => {
    if (filename) {
      setFile({ ...file, [filename]: props });
      setCompany({ ...company, [filename]: props });
    } else {
      if (props.target.name === "pan")
        return setCompany({
          ...company,
          [props.target.name]: props.target.value.toUpperCase(),
        });

      if (props.target.name === "gst")
        return setCompany({
          ...company,
          [props.target.name]: props.target.value.toUpperCase(),
        });

      setCompany({ ...company, [props.target.name]: props.target.value });
    }
  };

  const handleCountryChange = (value, type) => {
    setCompany((prev) => ({
      ...prev,
      [`country`]: value,
      [`state`]: "",
    }));
  };

  const handleStateChange = (value, type) => {
    setCompany((prev) => ({
      ...prev,
      [`state`]: value,
    }));
  };

  const handleImageChange = (file, arg2) => {
    if (file) {
      // Read the selected image as a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        if (arg2 === "logo") {
          setLogo({ show: reader.result, send: file });
        } else if (arg2 === "sign") {
          setSign({ show: reader.result, send: file });
        } else if (arg2 === "seal") {
          setCommonSeal({ show: reader.result, send: file });
        }
      };
      reader.readAsDataURL(file);
    } else {
      // If no file is selected, clear the selected image
    }
  };

  const handleChangeCheckBox = (isChecked, name) => {
    setCheck({ ...check, [name]: isChecked });
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Edit Company</h3>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <form className="forms-sample" onSubmit={manageSubmit}>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> Company Name *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="Company Name"
                              name="name"
                              value={company.name}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> Email Address *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="Email Address"
                              name="email"
                              value={company.email}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Website </label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="Website"
                              readOnly=""
                              defaultValue=""
                              name="website"
                              value={company.website}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Phone *</label>
                            <div className="input-group mb-3">
                              <div className="input-group-append">
                                <span className="input-group-text mdi mdi-phone h-100"></span>
                              </div>
                              <input
                                type="number"
                                className="form-control h-100"
                                placeholder="Phone"
                                name="phone"
                                value={company.phone}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="form-group">
                            <label> Address *</label>
                            <textarea
                              type="text"
                              className="form-control"
                              style={{ height: "47px" }}
                              placeholder="Address"
                              name="address"
                              value={company.address}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="select-container ">
                            <label className="col-lg-12 margin-bottom-dropdown mb-2">
                              {" "}
                              Country *
                            </label>
                            <Select
                              required
                              name="country"
                              className=""
                              value={{
                                value: company.country || "",
                                label: company.country || "",
                              }}
                              onChange={({ value }) =>
                                handleCountryChange(value, "country")
                              }
                              options={countryList.map((country) => ({
                                label: country,
                                value: country,
                              }))}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="select-container ">
                            <label className="col-lg-12 margin-bottom-dropdown mb-2">
                              {" "}
                              State *
                            </label>
                            {isCountryIndia ? (
                              <Select
                                name="state"
                                className="w-100"
                                required
                                value={{
                                  value: company.state || "",
                                  label: company.state || "",
                                }}
                                onChange={({ value }) =>
                                  handleStateChange(value, "state")
                                }
                                options={state.map((state) => ({
                                  label: state,
                                  value: state,
                                }))}
                                styles={selectCustomStyle}
                              />
                            ) : (
                              <input
                                value={company.state || ""}
                                onChange={handleChange}
                                name="state"
                                type="text"
                                className="form-control"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> City *</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control h-100"
                                placeholder="City"
                                name="city"
                                value={company.city}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> PIN *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="PIN"
                              value={company.pin}
                              name="pin"
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>UPI Id</label>
                            <input
                              className="form-control h-100"
                              name="upi"
                              placeholder="Enter UPI id"
                              value={company.upi}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> Invoice Color</label>
                            <div className="input-group">
                              <input
                                type="color"
                                className="form-control h-100 form-control-color"
                                id="exampleColorInput"
                                name="color"
                                value={company.color}
                                onChange={(e) => handleChange(e)}
                                title="Choose your color"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> Logo </label>
                            <input
                              type="file"
                              className="form-control h-100 "
                              name="logo"
                              onChange={(e) =>
                                handleImageChange(e.target.files[0], "logo")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2 mt-2">
                          <div className="form-group">
                            <img
                              src={logo.show || NoImage}
                              style={{ width: "180px", height: "130px" }}
                              className="img-thumbnail img-fluid"
                              alt=""
                            />
                          </div>
                          <input
                            type="checkbox"
                            className="checkbox margin-between"
                            name="is_logo"
                            checked={check.is_logo}
                            onChange={(e) =>
                              handleChangeCheckBox(e.target.checked, "is_logo")
                            }
                          />{" "}
                          <label className="mb-2">
                            Is Logo Printed on Invoice{" "}
                          </label>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Sign</label>
                            <input
                              type="file"
                              className="form-control h-100"
                              name="sign"
                              onChange={(e) =>
                                handleImageChange(e.target.files[0], "sign")
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2 mt-2">
                          <div className="form-group">
                            <img
                              src={sign.show || NoImage}
                              style={{ width: "140px", height: "130px" }}
                              className="img-thumbnail img-fluid"
                              alt=""
                            />
                          </div>
                          <input
                            type="checkbox"
                            className="checkbox margin-between"
                            name="is_sign"
                            checked={check.is_sign}
                            onChange={(e) =>
                              handleChangeCheckBox(e.target.checked, "is_sign")
                            }
                          />{" "}
                          <label>Is Signature Printed on Invoice</label>
                        </div>

                        <div className="col-lg-12  margin-vertical">
                          <div className="border"></div>
                        </div>

                        <div className="col-lg-12  margin-vertical">
                          <div className="form-group m-0 p-0">
                            <label>Company Information</label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> TIN *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="TIN"
                              name="tin"
                              value={company.tin}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> GSTIN *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="GSTIN"
                              name="gst"
                              maxLength="15"
                              minLength="15"
                              pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                              title="GSTIN should be in the format: 11POPLP4627X3Z2"
                              value={company.gst}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Service Tax No.</label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control h-100"
                                placeholder="Service Tax No."
                                name="service_tax"
                                value={company.service_tax}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>PAN *</label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control h-100"
                                placeholder="PAN"
                                name="pan"
                                maxLength="10"
                                minLength="10"
                                pattern="[A-Z]{5}[0-9]{4}[A-Z]"
                                value={company.pan}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>D.L.No. </label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="D.L.No."
                              readOnly=""
                              defaultValue=""
                              name="dl_no"
                              value={company.dl_no}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>CIN No.</label>
                            <input
                              className="form-control h-100"
                              name="cin_no"
                              placeholder="CIN No."
                              defaultValue={""}
                              value={company.cin_no}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Additional Detail</label>
                            <textarea
                              className="form-control h-100"
                              name="additional_detail"
                              placeholder="Additional Details"
                              defaultValue={""}
                              value={company.additional_detail}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Running Out Limit</label>
                            <input
                              type="number"
                              className="form-control h-100"
                              name="running_out_limit"
                              placeholder="Running Out Limit"
                              defaultValue={""}
                              value={company.running_out_limit}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Expense Invoice Prefix</label>
                            <input
                              className="form-control h-100"
                              name="purchase_inv_prefix"
                              placeholder="Purchase Invoice Prefix"
                              defaultValue={""}
                              value={company.purchase_inv_prefix}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Sales Invoice Prefix</label>
                            <input
                              className="form-control h-100"
                              name="sales_inv_prefix"
                              placeholder="Sales Invoice Prefix"
                              value={company.sales_inv_prefix}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Quotation Prefix</label>
                            <input
                              className="form-control h-100"
                              name="quotation_prefix"
                              placeholder="Quotation Prefix"
                              defaultValue={""}
                              value={company.quotation_prefix}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Type of Bussiness *</label>
                            <input
                              className="form-control h-100"
                              name="bussiness_type"
                              placeholder="Type of Bussiness"
                              defaultValue={""}
                              value={company.bussiness_type}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Common Seal</label>
                            <input
                              type="file"
                              className="form-control h-100"
                              name="common_seal"
                              placeholder="Common Seal"
                              onChange={(e) =>
                                handleImageChange(e.target.files[0], "seal")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mt-2 mb-2">
                          <div className="form-group">
                            <img
                              src={commonSeal.show || NoImage}
                              style={{ width: "180px", height: "130px" }}
                              alt=""
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              className="checkbox margin-between"
                              name="is_common_seal"
                              checked={check.is_common_seal}
                              onChange={(e) =>
                                handleChangeCheckBox(
                                  e.target.checked,
                                  "is_common_seal"
                                )
                              }
                            />{" "}
                            <label>Is Common Seal Printed on Invoice</label>
                          </div>
                        </div>
                        <div className="col-lg-12  margin-vertical">
                          <div className="border"></div>
                        </div>
                        <div className="col-lg-12  margin-vertical">
                          <div className="form-group m-0 p-0">
                            <label>AGGREGATE TURNOVER</label>
                          </div>
                        </div>
                        {/* <div className="col-4">
                          <div className="select-container ">
                            <label className="col-12 margin-bottom-dropdown mb-2">
                              {" "}
                              Financial Year *
                            </label>
                            <Select
                              styles={selectCustomStyle}
                              name="category"
                              className="w-100"
                              value={FYState.FYear}
                              placeholder={`Select FY year`}
                              onChange={(val) =>
                                dispatch(editFinancialYear(val))
                              }
                              options={FinancialYearArray}
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>E Commerce GST</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              name="e_commerce_gst"
                              placeholder="E Commerce GST"
                              defaultValue={""}
                              value={company.e_commerce_gst}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              className="checkbox margin-between"
                              name="is_bank_detail"
                              checked={check.is_bank_detail}
                              onChange={(e) =>
                                handleChangeCheckBox(
                                  e.target.checked,
                                  "is_bank_detail"
                                )
                              }
                            />{" "}
                            <label>Bank Details</label>
                            <br />
                            (Will be Printed on Normal Invoice)
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-warning me-2">
                        Submit
                      </button>
                      <button
                        type="button"
                        className="btn btn btn-secondary"
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageCompany;
