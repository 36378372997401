import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useQuery } from "@tanstack/react-query";
import { productApi } from "../../../assets/apis";
import axios from "axios";
import toast from "react-hot-toast";
import Footer from "../../../helper/Footer";
import { useNavigate } from "react-router-dom";
import { fetchBrands } from "../brands/Brand";
import { fetchCategories } from "../category/Category";
import { selectStyle } from "../../../assets/selectCustomStyle";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { hasPermission } from "../../../utils/helpers";
export const fetchProducts = async () => {
  const response = await axios.get(productApi);
  return response.data;
};

function ProductServices() {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);

  const dispatch = useDispatch();
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [categoryArray, setCategoryArray] = useState([]);
  const [brandArray, setBrandArray] = useState([]);
  const [productData, setProductData] = useState([]);
  const [filters, setFilters] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [queryParams, setQueryParams] = useState({ page: 1, pageSize: 10 });

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const navigate = useNavigate();

  // product section
  const fetchProducts = async ({ queryKey }) => {
    const [, queryParamsData] = queryKey;
    const queryParams = new URLSearchParams(queryParamsData);
    const response = await axios.get(`${productApi}?${queryParams}`);

    const { products, totalPages } = response.data;
    setProductData(products);
    setTotalPages(totalPages);
    return products;
  };
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["product", queryParams],
    queryFn: fetchProducts,
  });

  const deleteProduct = async (id) => {
    handleClosePortal();
    try {
      await axios.delete(`${productApi}/${id}`);
      toast.success("Item deleted successfully");
      refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error deleting product");
    }
  };

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };
  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };

  //filter login

  const onChangeFilterHandler = (e) => {
    const { name, value } = e.target;
    setFilters((pre) => ({ ...pre, [name]: value }));
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    setQueryParams((pre) => ({ ...pre, ...filters, ...pagination }));
  };

  const onResetHandler = () => {
    setFilters({});
    setQueryParams((pre) => ({ page: 1, pageSize: 10 }));
    setPagination({ page: 1, pageSize: 10 });
  };

  useEffect(() => {
    setQueryParams((pre) => ({ ...pre, ...pagination }));
  }, [pagination.page, pagination.pageSize, pagination]);

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  // brand logic
  const { data: brandData } = useQuery({
    queryKey: ["brand"],
    queryFn: fetchBrands,
  });

  useEffect(() => {
    if (brandData?.brands) {
      const temp = brandData.brands.map((brand) => {
        return { value: brand.name, label: brand.name };
      });
      setBrandArray(temp);
    }
  }, [brandData]);

  // category logic
  const { data: categoryData } = useQuery({
    queryKey: ["category"],
    queryFn: fetchCategories,
  });

  useEffect(() => {
    if (categoryData?.categories) {
      const temp = categoryData.categories.map((cat) => {
        return { value: cat.name, label: cat.name };
      });
      setCategoryArray(temp);
    }
  }, [categoryData]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Items" }));
    dispatch(setSiderBarActiveLink({ link: "items" }));
  }, []);
  return (
    <div className="main-panel">
      <div className="content-wrapper  ">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title"> Product & Services</div>
            {hasPermission(loggedUser, 11) && (
              <button
                className="btn btn-primary px-2 py-1"
                onClick={() => navigate("/add/item")}
              >
                Add Item
              </button>
            )}
          </div>
          <form className="row" onSubmit={onSearchHandler}>
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name">
                  Name
                </label>
                <input
                  type="test"
                  className="form-control"
                  name="name"
                  id="name"
                  value={filters.name || ""}
                  onChange={(e) => {
                    handleInputChangeWithCondition(e, /^[\s\S]*$/);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="type">
                  Type
                </label>
                <select
                  className="form-control"
                  name="type"
                  id="type"
                  value={filters.type || ""}
                  onChange={onChangeFilterHandler}
                  style={selectStyle}
                >
                  <option value="">-&nbsp;-select-&nbsp;-</option>
                  <option value="service">service</option>
                  <option value="product">product</option>
                </select>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="categoryName">
                  Category
                </label>
                <select
                  className="form-control"
                  name="categoryName"
                  id="categoryName"
                  value={filters.categoryName || ""}
                  onChange={onChangeFilterHandler}
                  style={selectStyle}
                >
                  <option value="">-&nbsp;-select-&nbsp;-</option>
                  {categoryArray.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="brandName">
                  Brand
                </label>
                <select
                  className="form-control"
                  name="brandName"
                  id="brandName"
                  value={filters.brandName || ""}
                  onChange={onChangeFilterHandler}
                  style={selectStyle}
                >
                  <option value="">-&nbsp;-select-&nbsp;-</option>
                  {brandArray.map((brand) => (
                    <option key={brand.value} value={brand.value}>
                      {brand.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className="col-lg-3 text-left"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div className="form-group text-right mt-1">
                <button
                  type="submit"
                  id="search"
                  className="btn btn-primary btn-sm mt-4"
                >
                  Search
                </button>
              </div>
              <div className="form-group text-right mt-1">
                <button
                  type="button"
                  id="reset"
                  className="btn btn-primary btn-sm mt-4"
                  onClick={onResetHandler}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>

          <div className="d-flex flex-column  align-items-center datatable">
            <DataTable
              value={productData}
              style={{ width: "100%" }}
              id="review-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                body={(rowData, { rowIndex }) =>
                  pagination.page * pagination.pageSize -
                  pagination.pageSize +
                  rowIndex +
                  1
                }
                style={{minWidth:"4rem"}}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="active"
                body={(rowData) => (rowData.type ? rowData.type : "-")}
                header="TYPE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="active"
                body={(rowData) =>
                  rowData.categoryName ? rowData.categoryName : "-"
                }
                header="CATEGORY"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="active"
                body={(rowData) =>
                  rowData.brandName ? rowData.brandName : "-"
                }
                header="BRAND"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="opening_qty_per"
                body={(rowData) =>
                  rowData.opening_qty_per ? rowData.opening_qty_per : "0"
                }
                header="QTY"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="s_price"
                body={(rowData) => (rowData.s_price ? rowData.s_price : "-")}
                header="PRICE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="UOM"
                body={(rowData) => (rowData.UOM ? rowData.UOM : "-")}
                header="UOM"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "5rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "2rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {hasPermission(loggedUser, 12) && (
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          navigate(`/edit/item/${data.id}`);
                        }}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 13) && (
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleOpenPortal(data);
                        }}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
            <div
              className=""
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-evenly",
                margin: "10px 0 0 0",
              }}
            >
              <select
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, pageSize: e.target.value };
                  })
                }
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === 1}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page - 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-left" />
              </button>
              <span>
                Page {pagination.page} of {totalPages}
              </span>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === totalPages}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page + 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-right" />
              </button>
            </div>
          </div>
        </div>
        {openPortal && (
          <ConfirmPortal
            onClose={handleClosePortal}
            type={"Item"}
            name={deletingItem.name}
            id={deletingItem.id}
            handleDalete={deleteProduct}
          ></ConfirmPortal>
        )}
      </div>
    </div>
  );
}

export default ProductServices;
