import { Toaster } from "react-hot-toast";
// import AllRoutes from "./components/pages/Routes";
import Router from "./components/pages/Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <Router />
    </QueryClientProvider>
  );
}

export default App;
