import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import axios from "axios";
import {
  loadUserFail,
  loadUserRequest,
  loadUserSuccess,
} from "../../redux-store/Auth";
import { userProfile } from "../../assets/apis";
import toast from "react-hot-toast";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";

const BlankLayout = () => {
  const { isAuthenticated, loading } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const UnProtectedRoute = ({ children }) => {
    if (loading === false) {
      if (!isAuthenticated) {
        return children;
      } else {
        return <Navigate to="/" />;
      }
    } else {
      return <CoverSpin />;
    }
  };

  const loadUser = async () => {
    try {
      dispatch(loadUserRequest());
      const response = await axios.get(userProfile);
      const user = response?.data?.data;
      dispatch(loadUserSuccess({ user }));
    } catch (error) {
      dispatch(loadUserFail());
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <>
    <ScrollToTop/>
    <UnProtectedRoute>
      <Outlet />
    </UnProtectedRoute>
    </>
  );
};

export default BlankLayout;
