import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { allGsthsnApi, gsthsnApi } from "../../../assets/apis";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { hasPermission } from "../../../utils/helpers";
export const fetchHsns = async () => {
  const response = await axios.get(allGsthsnApi);
  return response.data;
};

const GstHsn = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);
  const dispatch = useDispatch();
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [allGstHsn, setAllGstHsn] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
  });

  const navigate = useNavigate();

  const deleteGstHsn = async (id) => {
    handleClosePortal();
    try {
      await axios.delete(`${gsthsnApi}/${id}`);
      toast.success("HSN deleted successfully");
      refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error deleting HSN");
    }
  };

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };
  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };

  const fetchGsthsns = async () => {
    try {
      const response = await axios.get(
        `${gsthsnApi}?page=${Number(pagination.currentPage)}&pageSize=${Number(
          pagination.pageSize
        )}&search=${searchTerm}`
      );
      setAllGstHsn(response.data.gsthsns);
      setTotalPages(response.data.totalPages||1);
      return response.data;
    } catch (error) {}
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["gsthsns"],
    queryFn: fetchGsthsns,
    enabled: false,
  });

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setSearchTerm(Number(value));
    }
  };

  useEffect(() => {
    refetch();
  }, [pagination]);

  useEffect(() => {
    const timer = setTimeout(refetch, 500);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Taxes" }));
    dispatch(setSiderBarActiveLink({ link: "gsthsn" }));
  }, []);

  return (
    <div className="main-panel  ">
      <div className="content-wrapper">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title">
              {" "}
              All HSN{" "}
              <i
                className="fa fa-info-circle text-secondary hover-cursor"
                data-toggle="tooltip"
                title="“Harmonized System of Nomenclature” for the systematic classification of goods"
                aria-hidden="true"
                data-original-title="Contacts who can become our Future clients"
              ></i>
            </div>
            {hasPermission(loggedUser, 25) && (
              <button
                className="btn btn-primary d-flex justify-content-center align-items-center px-2 py-1"
                onClick={() => navigate("/add/gsthsn")}
              >
                {/* <i className="mdi  mdi-account-plus me-1"></i> */}
                Add HSN
              </button>
            )}
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name"></label>
                <input
                  type="text"
                  className="form-control "
                  name="name"
                  id="name"
                  placeholder="search"
                  value={searchTerm || ""}
                  onChange={(e) =>
                    handleInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column  align-items-center datatable">
            <DataTable
              value={allGstHsn}
              style={{ width: "100%" }}
              id="review-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top "
                header="SR. No."
                body={(rowData, { rowIndex }) =>
                  pagination.currentPage * pagination.pageSize -
                  pagination.pageSize +
                  rowIndex +
                  1
                }
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "4rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                field="hsn_code_no"
                header="HSN Code No"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "8rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="hsn_code"
                header="HSN Code"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="gst_rate"
                header="TAX (%)"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "7rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="active"
                body={(rowData) => (rowData.active ? "Active" : "InActive")}
                header="ACTIVE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "2rem",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {hasPermission(loggedUser, 26) && (
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/edit/gsthsn/${data.id}`)
                        }
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 27) && (
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleOpenPortal(data);
                        }}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
            <div
              className=""
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-evenly",
                margin: "10px 0 0 0",
              }}
            >
              <select
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, pageSize: e.target.value };
                  })
                }
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.currentPage === 1}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, currentPage: prev.currentPage - 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-left" />
              </button>
              <span>
                Page {pagination.currentPage} of {totalPages}
              </span>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.currentPage === totalPages}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, currentPage: prev.currentPage + 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"HSN"}
          name={deletingItem.hsn_code}
          id={deletingItem.id}
          handleDalete={deleteGstHsn}
        ></ConfirmPortal>
      )}
    </div>
  );
};

export default GstHsn;
