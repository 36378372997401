import { Fragment } from "react";
import ReactDOM from "react-dom";

import "./CoverSpin.css";

const Backdrop = ({ onClick }) => {
  return <div className="coverspinBackdrop" onClick={onClick} />;
};

const ModalOverlay = () => {
  return <div className="coverspinmodal"></div>;
};

const portalElement = document.getElementById("cover-spin");

const CoverSpin = () => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay></ModalOverlay>, portalElement)}
    </Fragment>
  );
};

export default CoverSpin;
