import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import CsvDownloader from "react-csv-downloader";
import * as XLSX from "xlsx";
import axios from "axios";
import { expenseInvoiceApi } from "../../assets/apis";
import { useSelector } from "react-redux";
import Spinner from "../../spinner/first_spinner/Spinner";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import {
  dateFormateDDMMYYYY,
  parseFloatWithFixedValue,
} from "../../utils/helpers";
import usePagination from "../../Pagination/Pagination";
import CustomPagination from "../../Pagination/CustomPagination";
import { tableStyle, tdThStyle } from "../../Pagination/tableStyle";
import { Link, useNavigate } from "react-router-dom";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";
import ResultNotFound from "../../notFound/ResultNotFound/ResultNotFound";

const SupplierReports = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { companyData } = useSelector((store) => store.Company);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [name, setName] = useState("");

  const onChangeFilterHandlerWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setName(value);
    }
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    if (startDate && endDate && startDate > endDate)
      return toast.error("Start date must be less than or equal to end date");
    setQueryParams({ startDate, endDate, name });
  };

  const onClearHandler = (e) => {
    setStartDate("");
    setEndDate("");
    setName("");
    setQueryParams({ startDate: "", endDate: "", name: "" });
  };

  const handleOnExportExcel = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(
      mapData(_DATA.currentData(), companyData)
    );
    XLSX.utils.book_append_sheet(wb, ws, "supplier_invoice_report");
    XLSX.writeFile(wb, "supplier_invoice_report.xlsx");
    setShow((pre) => !pre);
  };

  const [queryParams, setQueryParams] = useState({
    startDate: "",
    endDate: "",
    name: "",
  });

  const fetchPurchaseInvoices = async ({ queryKey }) => {
    const [, { startDate, endDate, name }] = queryKey;
    const queryParams = new URLSearchParams({ startDate, endDate, name });
    const response = await axios.get(
      `${expenseInvoiceApi}/get_purchase_inv_report?${queryParams}`
    );
    return response.data;
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["supplierReport", queryParams],
    queryFn: fetchPurchaseInvoices,
    retry: false,
  });

  const _DATA = usePagination(data?.purchaseInvoices || []);

  const handleChange = (e, p) => {
    _DATA.jump(p);
  };

  const handleOnChangePerPage = (e) => {
    const { value } = e.target;
    _DATA.showPerPage(value);
  };

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  // if (isLoading) {
  //   return (
  //     <div className="main-panel">
  //     <div className="content-wrapper">
  //       <SecondSpinner />
  //     </div>
  //   </div>
  //   );
  // }

  useEffect(() => {
    if (isError && error?.response?.status === 403) {
      navigate("/upgrade");
    }
  }, [isError, error, navigate]);

  if (isError) {
    return (
      <div> {error?.response?.data?.message || "Something went wrong"}</div>
    );
  }
  return (
    <>
      {isLoading && <Spinner />}
      <div className="col-12 h-5">
        <form onSubmit={onSearchHandler}>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name">
                  Customer Name
                </label>
                <input
                  type="test"
                  className="form-control form-control-alternative"
                  name="contact_name"
                  id="contact_name"
                  value={name}
                  onChange={(e) =>
                    onChangeFilterHandlerWithCondition(e, /^[\s\S]*$/)
                  }
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="startDate">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-alternative"
                  name="start_date"
                  id="start_date"
                  min="-04-01"
                  max="-12-31"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="endDate">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-alternative"
                  name="end_date"
                  id="end_date"
                  min="-01-01"
                  max="-03-31"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>

            <div className="col-lg-3 ">
              <div
                className="form-group mb-0 mt-3"
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  type="submit"
                  id="search_Supplier_reports"
                  className="btn btn-primary btn-sm mt-4"
                >
                  Search
                </button>
                <button
                  type="button"
                  id="reset"
                  className="btn btn-primary btn-sm mt-4"
                  onClick={onClearHandler}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm dropdown-toggle mt-4"
                  disabled={_DATA.currentData().length === 0}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setShow((pre) => !pre)}
                >
                  Download
                </button>
              </div>
              <div
                className={`dropdown-menu ${show && "show"}`}
                style={{ right: 0 }}
              >
                {/* <span
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  id="pdf"
                  onClick={handleOnExportPDF}
                >
                  Export PDF
                </span> */}
                <CSVLink
                  data={mapData(_DATA.currentData(), companyData)}
                  filename={"Supplier_invoice_report.csv"}
                  // target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <span
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    id="csv"
                    onClick={() => setShow((pre) => !pre)}
                  >
                    Export CSV
                  </span>
                </CSVLink>

                <span
                  className="dropdown-item cursor-pointer"
                  style={{ cursor: "pointer" }}
                  id="excel"
                  onClick={handleOnExportExcel}
                >
                  Export XLS
                </span>
                {/* <!-- <a className="dropdown-item" href="#">Print</a> --> */}
              </div>
            </div>
          </div>
        </form>
      </div>
      {_DATA.currentData().length === 0 ? (
        <ResultNotFound message={`Sorry, we counldn't find any results`} />
      ) : (
        <div className="table-responsive mt-4">
          <label className="mb-3">
            Show{" "}
            <select
              name="example_length"
              aria-controls="example"
              className=""
              onChange={handleOnChangePerPage}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={_DATA.length}>All</option>
            </select>{" "}
            entries
          </label>
          <div style={{ overflowX: "auto", scrollbarWidth: "thin" }}>
            <table
              className="table align-items-center table-flush mb-3"
              id="invoice_report_table"
              style={{ ...tableStyle }}
            >
              {/* klflkd */}
              <thead>
                <tr>
                  <th
                    scope="col"
                    colSpan={1}
                    style={{ ...tdThStyle, width: "70px" }}
                  >
                    Sr. No.
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "180px" }}>
                    Contact
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "82px" }}>
                    Invoice
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "94px" }}>
                    Date
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "134px" }}>
                    Sub Total
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "134px" }}>
                    Discount
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "120px" }}>
                    GST
                  </th>
                  {/* <th scope="col">Total Inv.</th> */}
                  <th scope="col" style={{ ...tdThStyle, width: "120px" }}>
                    Amount{" "}
                  </th>
                  {/* <th scope="col">Amount With Tax</th> */}
                  <th scope="col" style={{ ...tdThStyle, width: "134px" }}>
                    Paid
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "134px" }}>
                    Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                {_DATA.currentData().map((invoice, index) => (
                  <tr key={index}>
                    <td colSpan={1} style={{ ...tdThStyle, width: "70px" }}>
                      {(_DATA.currentPage - 1) * _DATA.perPage + (index + 1)}
                    </td>
                    <td style={{ ...tdThStyle, width: "180px" }}>
                      {invoice.name}
                    </td>
                    <td style={{ ...tdThStyle, width: "82px" }}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/invoices/view/expenses/${invoice?.invoice}`}
                        target="_blank"
                      >
                        {companyData?.purchase_inv_prefix
                          ? `${companyData.purchase_inv_prefix}${invoice?.invoice}`
                          : `EXP-${invoice?.invoice}`}
                      </Link>{" "}
                    </td>

                    <td style={{ ...tdThStyle, width: "94px" }}>
                      {dateFormateDDMMYYYY(invoice.date)}
                    </td>
                    <td style={{ ...tdThStyle, width: "134px" }}>
                      ₹ {invoice.amount || 0}
                    </td>
                    <td style={{ ...tdThStyle, width: "134px" }}>
                      ₹ {invoice.discount || 0}
                    </td>
                    <td style={{ ...tdThStyle, width: "120px" }}>
                      ₹ {invoice.GST || 0}
                    </td>
                    <td style={{ ...tdThStyle, width: "120px" }}>
                      ₹ {invoice.total || 0}
                    </td>
                    {/* <td>{invoice.total || 0}</td> */}
                    <td style={{ ...tdThStyle, width: "134px" }}>
                      ₹ {invoice.paid_amount || 0}
                    </td>
                    <td style={{ ...tdThStyle, width: "134px" }}>
                      ₹ {invoice.balance || 0}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={4} style={{ ...tdThStyle }}></td>

                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Subtotal)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce((total, invoice) => total + invoice.amount, 0)
                      )}
                    </span>
                  </td>
                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Discount)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce(
                            (total, invoice) => total + invoice.discount,
                            0
                          )
                      )}
                    </span>
                  </td>
                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Tax)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce((total, invoice) => total + invoice.GST, 0)
                      )}
                    </span>
                  </td>
                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Amount)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce((total, invoice) => total + invoice.total, 0)
                      )}
                    </span>
                  </td>
                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Received)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce(
                            (total, invoice) => total + invoice.paid_amount,
                            0
                          )
                      )}
                    </span>
                  </td>
                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Remaining)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce(
                            (total, invoice) => total + invoice.balance,
                            0
                          )
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CustomPagination
            count={_DATA.maxPage}
            page={_DATA.currentPage}
            length={_DATA.length}
            currentDataLength={_DATA.currentData().length}
            onChange={handleChange}
            perPage={_DATA.perPage}
          />
        </div>
      )}
    </>
  );
};

export default SupplierReports;

function mapData(invoices, companyData) {
  return invoices.map(
    (
      {
        id,
        GST,
        amount,
        balance,
        date,
        discount,
        invoice,
        name,
        paid_amount,
        paid_date,
        payment_status,
        shipping_address,
        total,
        total_tax,
      },
      index
    ) => ({
      ["Sr. No."]: index + 1,
      Invoice: companyData?.purchase_inv_prefix
        ? `${companyData.purchase_inv_prefix}${invoice}`
        : `EXP-${invoice}`,
      Name: name,
      Date: dateFormateDDMMYYYY(date),
      Address: shipping_address,
      Amount: amount,
      Discount: discount,
      Gst: GST,
      ["Total Amount"]: total,
      ["Paid Amount"]: paid_amount,
      ["Balance"]: balance,
      ["Paid Date"]: dateFormateDDMMYYYY(paid_date),
      ["Payment Status"]: payment_status,
    })
  );
}
