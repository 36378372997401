import React, { useEffect, useState } from "react";
import AddCustomer from "../../assets/images/logos/AddCustomer.png";
import Invoices from "../../assets/images/logos/Invoices.png";
import AddItem from "../../assets/images/logos/AddItem.png";
import Reports from "../../assets/images/logos/Reports.png";
import checkmark from "../../assets/images/logos/checkmark.png";
import axios from "axios";
import { companyApi, razorpayApi } from "../../assets/apis";
import { useDispatch, useSelector } from "react-redux";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import { Navigate, useNavigate } from "react-router-dom";
import { setCompanyData } from "../../redux-store/Company";
import { expiryDetails } from "../../utils/helpers";
import Spinner from "../../spinner/first_spinner/Spinner";
const BasicPlan = () => {
  const { token, loggedUser } = useSelector((state) => state.Auth);
  const { companyData } = useSelector((state) => state.Company);
  const [isLoading, setIsLoading] = useState(false);
  const [planAmount, setPlanAmount] = useState(5999);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCompany = async () => {
    try {
      const result = await axios.get(companyApi);
      const response = result.data;
      dispatch(setCompanyData(response.company));
    } catch (error) {}
  };

  const checkoutHandler = async () => {
    const {
      data: { key },
    } = await axios.get(razorpayApi + "/getkey");

    const {
      data: { order },
    } = await axios.post(razorpayApi + "/checkout", {
      amount: planAmount,
    });

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: companyData?.name,
      description: "",
      image: companyData?.logo,
      order_id: order.id,
      prefill: {
        name: companyData?.name,
        email: companyData?.email,
        contact: companyData?.phone,
      },
      handler: async (response) => {
        setIsLoading(true);
        setLoadingMessage("Verifying payment...");

        try {
          const { data } = await axios.post(
            razorpayApi + "/paymentVerification",
            {
              ...response,
              amount: planAmount,
            },
            {
              headers: {
                authorization: token,
              },
            }
          );
          getCompany();
          navigate("/mysubscription");
        } catch (error) {
          setLoadingMessage(
            error?.response?.data?.message || "Payment verification failed"
          );
        } finally {
          setIsLoading(false);
        }
      },
      notes: {
        address: companyData?.address,
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <div className="col-lg-12" style={{ padding: "1rem 0rem" }}>
      <div
        style={{
          background: "#E2DAD6",
          padding: "1rem 2rem",
          borderRadius: "8px",
        }}
      >
        <div className="col-lg-12 d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column gap-2">
            <h2 className="m-0 " style={{ fontWeight: "bold" }}>
              Plan Details
            </h2>
            <h6 className="m-0" style={{ fontWeight: "bold" }}>
              Here's what you get:
            </h6>
          </div>
          {/* <div
          style={{
            border: "1px solid black",
            padding: "4px",
            borderRadius: "8px",
          }}
        >
          <span
            className="mdi mdi-gift mdi-36px hover-cursor"
            style={{ height: "36px" }}
          ></span>
        </div> */}
        </div>
        <div className="col-lg-12 d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                borderRadius: "8px",
              }}
            >
              <img src={AddCustomer} alt="" width={60} />
            </div>
            <div className="d-flex flex-column gap-2">
              <h4 className="m-0 ">Customers</h4>
              <h6 className="m-0">Add Unlimited Customers</h6>
            </div>
          </div>
          <div
            style={{
              padding: "2px",
            }}
          >
            <img src={checkmark} alt="" width={40} />
          </div>
        </div>
        <div className="col-lg-12 d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                borderRadius: "8px",
              }}
            >
              <img src={AddItem} alt="" width={60} />
            </div>
            <div className="d-flex flex-column gap-2">
              <h4 className="m-0 ">Items</h4>
              <h6 className="m-0">Add Unlimited Items</h6>
            </div>
          </div>
          <div
            style={{
              padding: "2px",
            }}
          >
            <img src={checkmark} alt="" width={40} />
          </div>
        </div>
        <div className="col-lg-12 d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                borderRadius: "8px",
              }}
            >
              <img src={Invoices} alt="" width={60} />
            </div>
            <div className="d-flex flex-column gap-2">
              <h4 className="m-0 ">Invoices</h4>
              <h6 className="m-0">Create Unlimited Invoices</h6>
            </div>
          </div>
          <div
            style={{
              padding: "2px",
            }}
          >
            <img src={checkmark} alt="" width={40} />
          </div>
        </div>
        <div className="col-lg-12 d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                borderRadius: "8px",
              }}
            >
              <img src={Reports} alt="" width={60} />
            </div>
            <div className="d-flex flex-column gap-2">
              <h4 className="m-0 ">Reports</h4>
              <h6 className="m-0">Get All Reports</h6>
            </div>
          </div>
          <div
            style={{
              padding: "2px",
            }}
          >
            <img src={checkmark} alt="" width={40} />
          </div>
        </div>
        <form className="col-lg-12 d-flex align-items-center justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-success d-flex align-items-center justify-content-center"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
            onClick={checkoutHandler}
          >
            ₹&nbsp;5,999/Year&nbsp;|&nbsp;Pay Now&nbsp;
          </button>
        </form>
      </div>
      {isLoading && <Spinner />}
    </div>
  );
};

export default BasicPlan;
