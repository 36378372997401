import { toast } from "react-hot-toast";
export const productValidation = (productData) => {
  const { name, s_price, p_price, opening_qty_per } = productData;
  if (!name) {
    toast.error("Please enter a name");
    return false;
  }

  if (+s_price < 1) {
    toast.error("Please enter a valid sell price");
    return false;
  }
  if (+p_price && +p_price > +s_price) {
    toast.error("Purchase price must be less then or equal to Sell price");
    return false;
  }

  if (+opening_qty_per && +opening_qty_per < 1) {
    toast.error("Please enter atlease 1 unit");
    return false;
  }
  return true;
};
