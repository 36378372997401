// const domain = "http://localhost:5000";

// export const domain = "https://api.vyqdabills.com";
export const domain = "https://tempapi.vyqdabills.com";

export const loginApi = `${domain}/auth/login`;
export const signupApi = `${domain}/auth/signup`;
export const registerApi = `${domain}/auth/register`;
export const forgotPasswordApi = `${domain}/auth/forgotpassword`;
export const userProfile = `${domain}/user/profile`;
export const companyApi = `${domain}/company`;
export const brandApi = `${domain}/brand`;
export const allBrandApi = `${domain}/brand/allbrands`;
export const categoryApi = `${domain}/category`;
export const allCategoryApi = `${domain}/category/allcategories`;
export const taxApi = `${domain}/tax`;
export const allTaxApi = `${domain}/tax/alltaxes`;
export const gsthsnApi = `${domain}/gsthsn`;
export const allGsthsnApi = `${domain}/gsthsn/allhsns`;
export const gstsacApi = `${domain}/gstsac`;
export const allGstsacApi = `${domain}/gstsac/allsacs`;
export const financialYearApi = `${domain}/year`;
export const userApi = `${domain}/users`;
export const productApi = `${domain}/product`;
export const allProductApi = `${domain}/product/allproducts`;
export const contactApi = `${domain}/contact`;
export const allContactApi = `${domain}/contact/allcontacts`;
export const leadApi = `${domain}/lead`;
export const salesInvoiceApi = `${domain}/sales_inv`;
export const expenseInvoiceApi = `${domain}/purchase_inv`;
export const quotationApi = `${domain}/quotation`;
export const deliveryChallanApi = `${domain}/delivery_challan`;
export const noteApi = `${domain}/note`;
export const bankApi = `${domain}/bank`;
export const paymentApi = `${domain}/payment`;
export const expensePaymentApi = `${domain}/payment/expense/payment`;
export const payApi = `${domain}/pay`;
export const dashboardApi = `${domain}/dashboard`;
export const inventoryApi = `${domain}/inventory`;
export const razorpayApi = `${domain}/razorpay`;
