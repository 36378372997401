import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AppLogo from "../../../assets/AppLogo";
import { countryList } from "../../../assets/countryList";
import { state } from "../../../assets/StateList";
import { cityList } from "../../../assets/cityList";
import axios from "axios";
import { registerApi } from "../../../assets/apis";
function SignUp() {
  const navigate = useNavigate();
  const [tab, setTab] = useState("User");
  const [cities, setCities] = useState([]);
  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [apiInProccess, setApiInProccess] = useState(false);

  const handleChangeUserData = (e) => {
    const { name, value } = e.target;
    setUserData((pre) => ({ ...pre, [name]: value }));
  };

  const handleChangeUserDataWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setUserData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChangeCompanyData = (e, select_name) => {
    const { name, value } = e.target;
    setCompanyData((pre) => ({ ...pre, [name]: value }));
  };

  const handleCountryChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prev) => ({
      ...prev,
      [name]: value,
      state: "",
      city: "",
    }));
    setCities([]);
  };

  const handleStateChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((pre) => ({ ...pre, [name]: value, city: "" }));
    setCities(() => {
      const city = cityList[value];
      return city || [];
    });
  };

  const handleChangeCompanyDataWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      if (name === "pan")
        return setCompanyData((prev) => ({
          ...prev,
          [name]: value.toUpperCase(),
        }));
      if (name === "gst")
        return setCompanyData((prev) => ({
          ...prev,
          [name]: value.toUpperCase(),
        }));
      setCompanyData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onNextHandler = () => {
    const { name, email, password, confirmPassword, mobile } = userData;

    const userNameInput = document.getElementById("user-name");
    const userEmailInput = document.getElementById("user-email");
    const userMobile = document.getElementById("user-mobile");
    const userPassword = document.getElementById("user-password");
    const userConfirmPassowrd = document.getElementById("user-confirmPassword");
    const emailRegax = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!name || (name && !name.trim())) {
      userNameInput.reportValidity();
      toast.error("Please enter name");
      return;
    }

    if (!email || (email && !emailRegax.test(email))) {
      userEmailInput.reportValidity();
      toast.error("Please enter a valid email");
      return;
    }

    if (!mobile || mobile?.length !== 10) {
      userMobile.reportValidity();
      toast.error("Please enter a valid mobile number");
      return;
    }
    if (!password || password?.length < 8 || password?.length > 16) {
      userPassword.reportValidity();
      toast.error("password length should be between 8-16 characters");
      return;
    }
    if (
      !confirmPassword ||
      confirmPassword?.length < 8 ||
      confirmPassword?.length > 16
    ) {
      userConfirmPassowrd.reportValidity();
      toast.error("Confirm password length should be between 8-16 characters");
      return;
    }
    if (confirmPassword !== password) {
      toast.error("Confirm password should be match with password");
      return;
    }
    let updatedUserData = {
      ...userData,
      ...(name && { name: name.trim() }),
      ...(email && { email: email.trim() }),
      ...(password && { password: password.trim() }),
      ...(confirmPassword && { confirmPassword: confirmPassword.trim() }),
    };
    setUserData(updatedUserData);
    setTab("Company");
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phone, address, country, state, city, pin, pan, gst } =
      companyData;
    const companyNameInput = document.getElementById("company-name");
    const companyEmailInput = document.getElementById("company-email");
    const companyPhoneInput = document.getElementById("company-phone");
    const companyAddressInput = document.getElementById("company-address");
    const companyCountryInput = document.getElementById("company-country");
    const companStateInput = document.getElementById("company-state");
    const companCityInput = document.getElementById("company-city");
    const companyPinInput = document.getElementById("comany-pin");
    const companyPanInput = document.getElementById("company-pan");
    const companyGstInput = document.getElementById("company-gst");
    if (!name || (name && !name.trim())) {
      companyNameInput.reportValidity();
      toast.error("Please enter company name");
      return;
    }
    const emailRegax = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email || (email && !emailRegax.test(email))) {
      companyEmailInput.reportValidity();
      toast.error("Please enter a valid email address");
    }

    if (!phone || phone?.length !== 10) {
      companyPhoneInput.reportValidity();
      toast.error("Please enter a valid mobile number");
      return;
    }

    if (!address || (address && !address.trim())) {
      companyAddressInput.reportValidity();
      toast.error("Please enter company Address");
      return;
    }

    if (!country) {
      companyCountryInput.reportValidity();
      toast.error("Please Select company Address");
      return;
    }

    if (!state || (state && !state.trim())) {
      companStateInput.reportValidity();
      toast.error("Please fill or choose State");
      return;
    }
    if (!city || (city && !city.trim())) {
      companCityInput.reportValidity();
      toast.error("Please fill or choose City");
      return;
    }

    if (!pin) {
      companyPinInput.reportValidity();
      toast.error("Please enter pincode");
      return;
    }
    if (!pan) {
      companyPanInput.reportValidity();
      toast.error("Please enter Pan number");
      return;
    }
    if (!gst) {
      companyGstInput.reportValidity();
      toast.error("Please enter Gst number");
      return;
    }

    const updatedCompanyData = {
      ...companyData,
      ...(name && { name: name.trim() }),
      ...(email && { email: email.trim() }),
      ...(address && { address: address.trim() }),
      ...(state && { state: state.trim() }),
      ...(city && { city: city.trim() }),
    };
    setCompanyData(updatedCompanyData);
    setApiInProccess(true);

    try {
      const result = await axios.post(registerApi, {
        userData,
        companyData: updatedCompanyData,
      });
      const response = result.data;
      toast.success(
        response?.message || "Successfully registered Company and User."
      );
      navigate("/auth/login");
    } catch (error) {
      const err = error?.response?.data;
      const message = err?.message || "Something went wrong";
      toast.error(message);
    } finally {
      setApiInProccess(false);
    }
  };

  return (
    <div className="content-wrapper d-flex align-items-center auth px-0">
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo">
              <AppLogo />
            </div>
            <h4>Hello! let's get started</h4>
            <h6 className="fw-light">Signup to continue.</h6>

            <h4 className="mt-4">
              {tab === "User" ? "User Details" : "Company Details"}
            </h4>

            <form className="pt-3" onSubmit={onHandleSubmit}>
              {tab === "User" ? (
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="user-name"
                      name="name"
                      placeholder="User Name"
                      required
                      value={userData.name || ""}
                      onChange={(e) =>
                        handleChangeUserDataWithCondition(e, /^[a-zA-Z\s\b]+$/)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="user-email"
                      name="email"
                      placeholder="User Email"
                      required
                      value={userData.email || ""}
                      onChange={handleChangeUserData}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="user-mobile"
                      name="mobile"
                      placeholder="Mobile"
                      minLength={10}
                      maxLength={10}
                      required
                      autoComplete="new-mobile"
                      value={userData.mobile || ""}
                      onChange={(e) =>
                        handleChangeUserDataWithCondition(e, /^[0-9\b]+$/)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="user-password"
                      name="password"
                      placeholder="Password"
                      required
                      minLength={8}
                      maxLength={16}
                      autoComplete="new-password"
                      value={userData.password || ""}
                      onChange={handleChangeUserData}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="user-confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                      required
                      value={userData.confirmPassword || ""}
                      onChange={handleChangeUserData}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="company-name"
                      name="name"
                      placeholder="Company Name"
                      required
                      value={companyData.name || ""}
                      onChange={(e) =>
                        handleChangeCompanyDataWithCondition(
                          e,
                          /^[\s\S]*$/
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      id="company-email"
                      name="email"
                      placeholder="Company Email"
                      required
                      value={companyData.email || ""}
                      onChange={handleChangeCompanyData}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="company-phone"
                      name="phone"
                      placeholder="Phone Number"
                      minLength={10}
                      maxLength={10}
                      required
                      value={companyData.phone || ""}
                      onChange={(e) =>
                        handleChangeCompanyDataWithCondition(e, /^[0-9\b]+$/)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="company-address"
                      name="address"
                      placeholder="Company Address"
                      required
                      value={companyData.address || ""}
                      onChange={handleChangeCompanyData}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div className="form-group">
                      <select
                        id="company-country"
                        className="form-control form-control-lg"
                        style={{ color: "black" }}
                        name="country"
                        required
                        onChange={handleCountryChange}
                        value={companyData.country || ""}
                      >
                        <option value="" disabled>
                          -- Select Country --
                        </option>
                        {countryList.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      {companyData?.country === "India" ? (
                        <select
                          id="company-state"
                          className="form-control form-control-lg"
                          style={{ color: "black" }}
                          name="state"
                          required
                          onChange={handleStateChange}
                          value={companyData.state || ""}
                        >
                          <option value="" disabled>
                            -- Select State --
                          </option>
                          {state.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="company-state"
                          name="state"
                          placeholder="State"
                          required
                          value={companyData.state || ""}
                          onChange={handleChangeCompanyData}
                        />
                      )}
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div className="form-group">
                      {companyData?.country === "India" &&
                      companyData?.state &&
                      cities.length ? (
                        <select
                          id="company-city"
                          className="form-control form-control-lg"
                          style={{ color: "black" }}
                          name="city"
                          required
                          onChange={handleChangeCompanyData}
                          value={companyData.city || ""}
                        >
                          <option value="" disabled>
                            -- Select city --
                          </option>
                          {cities?.map(({ city }) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="company-city"
                          name="city"
                          placeholder="City"
                          required
                          value={companyData.city || ""}
                          onChange={handleChangeCompanyData}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="comany-pin"
                        name="pin"
                        placeholder="Pincode"
                        minLength={6}
                        maxLength={6}
                        required
                        value={companyData.pin || ""}
                        onChange={(e) =>
                          handleChangeCompanyDataWithCondition(e, /^[0-9\b]+$/)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="company-pan"
                      name="pan"
                      placeholder="Enter Pan Number"
                      required
                      maxLength="10"
                      minLength="10"
                      pattern="[A-Z]{5}[0-9]{4}[A-Z]"
                      value={companyData.pan || ""}
                      onChange={(e) =>
                        handleChangeCompanyDataWithCondition(
                          e,
                          /^[a-zA-Z0-9]+$/
                        )
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="company-gst"
                      name="gst"
                      maxLength="15"
                      minLength="15"
                      pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                      title="GSTIN should be in the format: 11POPLP4627X3Z2"
                      placeholder="Enter Gst Number"
                      required
                      value={companyData.gst || ""}
                      onChange={(e) =>
                        handleChangeCompanyDataWithCondition(
                          e,
                          /^[a-zA-Z0-9]+$/
                        )
                      }
                    />
                  </div>
                </div>
              )}

              {tab === "User" ? (
                <div className="mt-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-block btn-primary "
                    onClick={onNextHandler}
                  >
                    Next
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={(e) => setTab("User")}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-block btn-primary"
                    type="submit"
                    disabled={apiInProccess}
                  >
                    Create
                  </button>
                </div>
              )}
            </form>
            <div className="text-center mt-4 fw-light">
              Already have an account?{" "}
              <Link to="/auth/login" className="text-primary">
                login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
