import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { loginApi } from "../../assets/apis";
import { toast } from "react-hot-toast";
import AppLogo from "../../assets/AppLogo";
import { loginSuccess } from "../../redux-store/Auth";

function Login() {
  const [data, setData] = useState({});
  const [apiInProccess, setApiInProccess] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = data;
    if (!email.trim() || !email.includes("@")) {
      toast.error("Please enter a valid email address");
      return;
    } else if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    }
    setApiInProccess(true);

    axios
      .post(loginApi, data)
      .then((response) => {
        const { success, message, token, user } = response.data;
        if (success) {
          toast.success(message);
          dispatch(loginSuccess({ token, user }));
          navigate("/");
        }
      })
      .catch((error) => {
        setApiInProccess(false);

        const message =
          error?.response?.data?.message || "Internal Server Error";
        toast.error(message);
      });
  };

  return (
    <div
      className="content-wrapper d-flex align-items-center auth px-0"
      style={{ height: "100vh" }}
    >
      <div className="row w-100 mx-0 ">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="brand-logo" style={{ height: "40px" }}>
              <AppLogo />
            </div>
            <h4>Hello! let's get started</h4>
            <h6 className="fw-light">Login to continue.</h6>
            <form className="pt-3" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="email"
                  placeholder="Email address"
                  autoComplete="current-password"
                  onChange={handleChange}
                  value={data.email || ""}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-lg"
                  id="password"
                  minLength={8}
                  maxLength={16}
                  placeholder="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  value={data.password || ""}
                  required
                />
              </div>

              <button className="btn btn-block btn-primary" type="submit" disabled={apiInProccess}>
                Login
              </button>
            </form>
            <div className="my-2 d-flex justify-content-end align-items-center">
              {/* <div className="form-check">
                <label className="form-check-label text-muted">
                  <input type="checkbox" className="form-check-input" />
                  Keep me logged in
                </label>
              </div> */}
              <Link to="/auth/forgot" className="text-primary">
                Forgot password?
              </Link>
            </div>

            <div className="text-center mt-4 fw-light">
              Don't have an account?{" "}
              <Link to="/auth/signup" className="text-primary">
                Create
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
