import React, { useEffect, useMemo, useState } from "react";
import normal from "../../assets/images/theme/normal.JPG";
import first from "../../assets/images/theme/1.png";
import second from "../../assets/images/theme/2.png";
import third from "../../assets/images/theme/3.png";
import thermal from "../../assets/images/theme/thermal.jpg";
import { companyApi } from "../../assets/apis";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "../../redux-store/Company";
const imagesObj = {
  normal: normal,
  // first: first,
  // second: second,
  // third: third,
  thermal: thermal,
};

const templates = [
  { value: "normal", label: "Normal Print Layout" },
  // { value: "first", label: "Print Layout Template 1" },
  // { value: "second", label: "Print Layout Template 2" },
  // { value: "third", label: "Print Layout Template 3" },
  { value: "thermal", label: "Thermal Print Layout" },
];

const PdfSelect = () => {
  const [pdfType, setPdfType] = useState("");
  const { companyData } = useSelector((state) => state.Company);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setPdfType(e.target.value);
  };

  const getCompany = async () => {
    try {
      const result = await axios.get(companyApi);
      const response = result.data;
      dispatch(setCompanyData(response.company));
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("companyDetails", JSON.stringify({ pdfLayout: pdfType }));
    try {
      await axios.put(companyApi, formData);
      getCompany();
      toast.success("Pdf Layout saved successfully!");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    }
  };

  const memoizedData = useMemo(() => {
    if (companyData) {
      const { pdfLayout } = companyData;
      return pdfLayout;
    }
    return "";
  }, [companyData]);

  useEffect(() => {
    setPdfType(memoizedData);
  }, [memoizedData]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline">
              <h3 className="ukhd mb-3">Select Layout</h3>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-3 mb-3">
                          {templates.map((template) => (
                            <div className="form-group" key={template.value}>
                              <input
                                type="radio"
                                className="change_template"
                                name="template_select"
                                value={template.value}
                                required
                                checked={pdfType === template.value}
                                onChange={handleInputChange}
                              />
                              <label
                                className="form-control-label"
                                htmlFor={`input-${template.value}`}
                              >
                                {template.label}
                              </label>
                            </div>
                          ))}
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm px-3 py-2"
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-md-8">
                          <div
                            id="pdf_format"
                            style={{
                              border: "1px solid #e6e6e6",
                              padding: "1rem",
                            }}
                          >
                            <img
                              src={imagesObj[pdfType]}
                              width={pdfType === "thermal" ? "50%" : "100%"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfSelect;
