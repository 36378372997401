import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { gsthsnApi } from "../../../assets/apis";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { useDispatch } from "react-redux";

const AddGstHsn = () => {
  const [addHsnData, setAddHsnData] = useState({ active: true });
  const [apiInProccess, setApiInProccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAddHsnData((pre) => ({
      ...pre,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleInputChangeWithValidation = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddHsnData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setAddHsnData((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name_of_commodity } = addHsnData;
    let updatedHsnData = {
      ...addHsnData,
      ...(name_of_commodity && { name_of_commodity: name_of_commodity.trim() }),
    };

    setAddHsnData(updatedHsnData);
    if (!updatedHsnData.name_of_commodity)
      return toast.error("Please enter name of commodity");
    if (+updatedHsnData.gst_rate > 100)
      return toast.error("Please enter a valid rate");
    setApiInProccess(true);

    try {
      await axios.post(gsthsnApi, updatedHsnData);
      toast.success("HSN added successfully");
      navigate("/gsthsn");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setApiInProccess(false);
    }
  };

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Taxes" }));
    dispatch(setSiderBarActiveLink({ link: "gsthsn" }));
  }, []);

  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Add HSN</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/gsthsn")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="col-lg-12">
                      <div className="col-lg-12 ">
                        <h5>HSN INFORMATION *</h5>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 mt-2">
                            <label>Hsn Code No. *</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="HSN Code No."
                              name="hsn_code_no"
                              required
                              minLength={4}
                              maxLength={8}
                              value={addHsnData.hsn_code_no || ""}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[0-9\b]+$/)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Hsn Code *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="HSN Code"
                              name="hsn_code"
                              required
                              minLength={4}
                              maxLength={8}
                              value={addHsnData.hsn_code || ""}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[0-9\b]+$/)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Name of Commodity *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Commodity"
                              name="name_of_commodity"
                              required
                              value={addHsnData.name_of_commodity || ""}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[\s\S]*$/)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label>Gst Rate (%) *</label>{" "}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="GST Rate"
                              name="gst_rate"
                              required
                              minLength={1}
                              maxLength={3}
                              value={addHsnData.gst_rate || ""}
                              onChange={(e) =>
                                handleInputChangeWithValidation(e, /^[0-9\b]+$/)
                              }
                            />
                          </div>
                          <div className="col-lg-12 mt-2">
                            <label>Active ? </label>{" "}
                            <input
                              type="checkbox"
                              name="active"
                              checked={addHsnData.active || ""}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-9 mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex justify-content-center px-3 py-2"
                            disabled={apiInProccess}
                          >
                            <i className="mdi mdi-content-save me-1"></i> Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGstHsn;
