import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  leadIndustryOptions,
  leadRateOptions,
  leadSourceArray,
  leadStatusOptions,
} from "../../../assets/leadOptions";
import { countryList } from "../../../assets/countryList";
import { leadApi } from "../../../assets/apis";
import { state } from "../../../assets/StateList";
import { selectCustomStyle } from "../../../assets/selectCustomStyle";
import { validateLead } from "./validateLead";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { useDispatch } from "react-redux";

const AddLead = () => {
  const [leadData, setLeadData] = useState({});
  const [apiInProccess, setApiInProccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateLeadData = (e, select_name) => {
    if (select_name) {
      setLeadData((prev) => ({ ...prev, [select_name]: e }));
    } else {
      const { name, value, type, checked } = e.target;
      const inputValue = type === "checkbox" ? checked : value;
      if (name === "PAN")
        return setLeadData((prev) => ({
          ...prev,
          [name]: inputValue.toUpperCase(),
        }));
      if (name === "GSTIN")
        return setLeadData((prev) => ({
          ...prev,
          [name]: inputValue.toUpperCase(),
        }));
      setLeadData((prev) => ({ ...prev, [name]: inputValue }));
    }
  };

  const handleInputChange = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setLeadData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const isBilling_countryIndia = leadData.billing_country === "India";
  const isShiping_countryIndia = leadData.shipping_country === "India";

  const handleCountryChange = (value, type) => {
    setLeadData((prev) => ({
      ...prev,
      [`${type}_country`]: value,
      [`${type}_state`]: "",
    }));
  };

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data) => {
      return axios.post(leadApi, data);
    },
    onSuccess: () => {
      setApiInProccess(false);

      queryClient.invalidateQueries(["leads"]);
      toast.success("Lead saved successfully!");
      navigate("/leads");
    },
    onError: (error) => {
      setApiInProccess(false);

      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    },
  });
  const saveLead = async (e) => {
    e.preventDefault();
    const {
      name,
      display_name,
      shipping_name,
      shipping_display_name,
      isBillAndShipAddressSame,
    } = leadData;

    let updatedLeadData = {
      ...leadData,
      name: name.trim(),
      display_name: display_name.trim(),
      ...(shipping_name && { shipping_name: shipping_name.trim() }),
      ...(shipping_display_name && {
        shipping_display_name: shipping_display_name.trim(),
      }),
    };

    if (isBillAndShipAddressSame) {
      updatedLeadData = {
        ...updatedLeadData,
        shipping_name: "",
        shipping_display_name: "",
        shipping_phone: "",
        shipping_email: "",
        shipping_address: "",
        shipping_country: "",
        shipping_state: "",
        shipping_city: "",
        shipping_pin_code: "",
      };
    }

    setLeadData(updatedLeadData);
    if (validateLead(updatedLeadData)) {
      setApiInProccess(true);

      mutation.mutate(updatedLeadData);
    }
  };

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Contacts" }));
    dispatch(setSiderBarActiveLink({ link: "leads" }));
  }, []);

  return (
    <div className={`main-panel`}>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">Add Lead</h3>
              <button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => navigate("/leads")}
              >
                <div className="d-flex justify-content-center">
                  <i className="mdi mdi mdi-keyboard-backspace"></i>
                  <span>Back</span>
                </div>
              </button>
            </div>
            <div className="row flex-grow">
              <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="col-12">
                      <div className="col-12 ">
                        <h5>LEAD INFORMATION</h5>
                      </div>

                      <form className="row" onSubmit={saveLead}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Contact Name *</label>
                            <input
                              required
                              value={leadData.name || ""}
                              onChange={(e) =>
                                handleInputChange(e, /^[\s\S]*$/)
                              }
                              name="name"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Display Name *</label>
                            <input
                              required
                              value={leadData.display_name || ""}
                              onChange={(e) =>
                                handleInputChange(e, /^[\s\S]*$/)
                              }
                              name="display_name"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Phone *</label>
                            <input
                              required
                              value={leadData.phone || ""}
                              onChange={(e) =>
                                handleInputChange(e, /^[0-9\b]+$/)
                              }
                              name="phone"
                              minLength={10}
                              maxLength={10}
                              type="tel"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              className="form-control"
                              name="email"
                              type="email"
                              value={leadData.email || ""}
                              onChange={updateLeadData}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>GSTIN</label>
                            <input
                              value={leadData.GSTIN || ""}
                              onChange={updateLeadData}
                              name="GSTIN"
                              maxLength="15"
                              minLength="15"
                              pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                              title="GSTIN should be in the format: 11POPLP4627X3Z2"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>TIN</label>
                            <input
                              value={leadData.TIN || ""}
                              onChange={updateLeadData}
                              name="TIN"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>PAN</label>
                            <input
                              value={leadData.PAN || ""}
                              onChange={updateLeadData}
                              name="PAN"
                              maxLength="10"
                              minLength="10"
                              pattern="[A-Z]{5}[0-9]{4}[A-Z]"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>VAT NO</label>
                            <input
                              value={leadData.VAT || ""}
                              onChange={updateLeadData}
                              name="VAT"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>DL. No.</label>
                            <input
                              value={leadData.DL || ""}
                              onChange={updateLeadData}
                              name="DL"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Billing Address</label>
                            <input
                              value={leadData.billing_address || ""}
                              onChange={updateLeadData}
                              name="billing_address"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Billing Country *</label>
                            <Select
                              required
                              name="billing_country"
                              className=""
                              value={{
                                value: leadData.billing_country || "",
                                label: leadData.billing_country || "",
                              }}
                              onChange={({ value }) =>
                                handleCountryChange(value, "billing")
                              }
                              options={countryList.map((country) => ({
                                label: country,
                                value: country,
                              }))}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              Billing State {isBilling_countryIndia && "*"}
                            </label>
                            {isBilling_countryIndia ? (
                              <Select
                                name="billing_state"
                                className="w-100"
                                required={isBilling_countryIndia}
                                value={{
                                  value: leadData.billing_state || "",
                                  label: leadData.billing_state || "",
                                }}
                                onChange={({ value }) =>
                                  updateLeadData(value, "billing_state")
                                }
                                options={state.map((state) => ({
                                  label: state,
                                  value: state,
                                }))}
                                styles={selectCustomStyle}
                              />
                            ) : (
                              <input
                                value={leadData.billing_state || ""}
                                onChange={updateLeadData}
                                name="billing_state"
                                type="text"
                                className="form-control"
                              />
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Billing City</label>
                            <input
                              value={leadData.billing_city || ""}
                              onChange={updateLeadData}
                              name="billing_city"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Billing PIN Code</label>
                            <input
                              value={leadData.billing_PIN_Code || ""}
                              onChange={(e) =>
                                handleInputChange(e, /^[0-9\b]+$/)
                              }
                              minLength={6}
                              maxLength={6}
                              name="billing_PIN_Code"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Lead Source</label>
                            <Select
                              name="source"
                              className="w-100"
                              value={{
                                label: leadData.source || "",
                                value: leadData.source || "",
                              }}
                              onChange={(data) =>
                                updateLeadData(data?.value || "", "source")
                              }
                              isClearable
                              options={leadSourceArray}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Lead Status</label>
                            <Select
                              name="status"
                              className="w-100"
                              value={{
                                label: leadData.status || "",
                                value: leadData.status || "",
                              }}
                              onChange={(data) =>
                                updateLeadData(data?.value || "", "status")
                              }
                              isClearable
                              options={leadStatusOptions}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Industry</label>
                            <Select
                              name="industry"
                              className="w-100"
                              value={{
                                label: leadData.industry || "",
                                value: leadData.industry || "",
                              }}
                              onChange={(data) =>
                                updateLeadData(data?.value || "", "industry")
                              }
                              isClearable
                              options={leadIndustryOptions}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>Rating</label>
                            <Select
                              name="rating"
                              className="w-100"
                              value={{
                                label: leadData.rating || "",
                                value: leadData.rating || "",
                              }}
                              onChange={(data) =>
                                updateLeadData(data?.value || "", "rating")
                              }
                              isClearable
                              options={leadRateOptions}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>

                        <div className="col-12 mb-3">
                          <input
                            checked={!!leadData.isBillAndShipAddressSame}
                            onChange={updateLeadData}
                            type="checkbox"
                            className="form-check-input"
                            id="isBillAndShipAddressSame"
                            name="isBillAndShipAddressSame"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isBillAndShipAddressSame"
                          >
                            Billing and shipping addresses are the same
                          </label>
                        </div>
                        {!leadData.isBillAndShipAddressSame && (
                          <>
                            <div className="col-12 mt-3">
                              <h6>SHIPPING INFORMATION</h6>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Contact Name *</label>
                                <input
                                  required
                                  value={leadData.shipping_name || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, /^[\s\S]*$/)
                                  }
                                  name="shipping_name"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Display Name *</label>
                                <input
                                  required
                                  value={leadData.shipping_display_name || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, /^[\s\S]*$/)
                                  }
                                  name="shipping_display_name"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Phone</label>
                                <input
                                  value={leadData.shipping_phone || ""}
                                  onChange={(e) =>
                                    handleInputChange(e, /^[0-9\b]+$/)
                                  }
                                  minLength={10}
                                  maxLength={10}
                                  name="shipping_phone"
                                  type="tel"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Email</label>
                                <input
                                  value={leadData.shipping_email || ""}
                                  onChange={updateLeadData}
                                  name="shipping_email"
                                  type="email"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Address</label>
                                <input
                                  value={leadData.shipping_address || ""}
                                  onChange={updateLeadData}
                                  name="shipping_address"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping Country *</label>
                                <Select
                                  name="shipping_country"
                                  required
                                  className="w-100"
                                  value={{
                                    value: leadData.shipping_country || "",
                                    label: leadData.shipping_country || "",
                                  }}
                                  onChange={({ value }) =>
                                    handleCountryChange(value, "shipping")
                                  }
                                  options={countryList.map((country) => ({
                                    label: country,
                                    value: country,
                                  }))}
                                  styles={selectCustomStyle}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Shipping State {isShiping_countryIndia && "*"}
                                </label>
                                {isShiping_countryIndia ? (
                                  <Select
                                    name="shipping_state"
                                    required={isShiping_countryIndia}
                                    className="w-100"
                                    value={{
                                      value: leadData.shipping_state || "",
                                      label: leadData.shipping_state || "",
                                    }}
                                    onChange={({ value }) =>
                                      updateLeadData(value, "shipping_state")
                                    }
                                    options={state.map((state) => ({
                                      label: state,
                                      value: state,
                                    }))}
                                    styles={selectCustomStyle}
                                  />
                                ) : (
                                  <input
                                    value={leadData.shipping_state || ""}
                                    onChange={updateLeadData}
                                    name="shipping_state"
                                    type="text"
                                    className="form-control"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Shipping City</label>
                                <input
                                  value={leadData.shipping_city || ""}
                                  onChange={updateLeadData}
                                  name="shipping_city"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>shipping PIN Code</label>
                                <input
                                  value={leadData.shipping_pin_code || ""}
                                  onChange={updateLeadData}
                                  name="shipping_pin_code"
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex justify-content-center px-3 py-2"
                            disabled={apiInProccess}
                          >
                            <i className="mdi mdi-content-save me-1"></i> Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLead;
