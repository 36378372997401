import React, { useEffect } from "react";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../redux-store/sidebarActiveList";
import { useDispatch } from "react-redux";
// import supportIcon from "../assets/icons/help-call_4874360.png";

function Support() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: null }));
    dispatch(setSiderBarActiveLink({ link: "support" }));
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper  ">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-12 grid-margin stretch-card">
              <div className="card card-rounded">
                <div className="card-body">
                  <img
                    src={"#"}
                    style={{ borderRadius: "20%", width: "10rem" }}
                    alt=""
                  />
                  <h2>Support</h2>
                  <hr />
                  <h6>
                    <marquee>
                      Need help with your purchease, part payment account or
                      anything we're available on the chat 24/7.
                    </marquee>
                  </h6>
                  <hr />
                  <h2>Conversations</h2>
                  <br />

                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    style={{ borderRadius: "50px" }}
                  >
                    <i className="bi bi-envelope"></i> contact@vyqda.com
                  </button>
                  <br />
                  <br />

                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    style={{ borderRadius: "50px" }}
                  >
                    <i className="bi bi-telephone"></i> 721.721.5635
                  </button>
                  <br />
                  <br />

                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    style={{ borderRadius: "50px" }}
                  >
                    <i className="bi bi-geo-alt"></i> VYQDA TECHNOLOGIES P. LTD.
                    2nd Floor, Rajrat Complex, Shastripuram, Agra (UP) 282007
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
