import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";

import Spinner from "../../spinner/first_spinner/Spinner";
import { companyApi } from "../../assets/apis";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "../../redux-store/Company";

let successMessage = "Changes Saved Successfully";

const Settings = () => {
  const [success, setSuccess] = useState(false);
  const { companyData } = useSelector((state) => state.Company);
  const dispatch = useDispatch();
  const [invoicesNumber, setInvoicesNumber] = useState({});

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setInvoicesNumber((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const handleCloseSuccessMessage = () => {
    setSuccess(false);
  };

  const memoizedData = useMemo(() => {
    if (companyData?.inv_no_start_from) {
      const obj = companyData.inv_no_start_from;
      return obj;
    }
    return {};
  }, [companyData]);

  useEffect(() => {
    setInvoicesNumber((pre) => ({
      ...pre,
      ...memoizedData,
    }));
  }, [memoizedData]);

  const getCompany = async () => {
    try {
      const result = await axios.get(companyApi);
      const response = result.data;
      dispatch(setCompanyData(response.company));
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append(
      "companyDetails",
      JSON.stringify({ inv_no_start_from: invoicesNumber })
    );
    try {
      await axios.put(companyApi, formdata);
      toast.success("Changes Saved Successfully");
      getCompany();
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(` ${errorMessage}`);
    }
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline">
              <h3 className="ukhd mb-3">Settings</h3>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="col-lg-12 ">
                      <h5>
                        You can set the starting point for the invoices numbers
                        in the app to any number you want.
                      </h5>
                    </div>
                    {success && (
                      <div
                        className="alert alert-success mb-5"
                        role="alert"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {successMessage}
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={handleCloseSuccessMessage}
                        ></button>
                      </div>
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="row">
                            <div className="col">
                              <div className="custom-control custom-text">
                                <label
                                  className="custom-control-label"
                                  htmlFor="sales"
                                >
                                  Sales
                                </label>
                                <input
                                  type="text"
                                  className="form-control mt-3"
                                  id="sales"
                                  name="sales"
                                  required
                                  value={invoicesNumber.sales || ""}
                                  onChange={(e) =>
                                    handleInputChangeWithConditonTypeNumber(
                                      e,
                                      /^[0-9\b]+$/
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col">
                              <div className="custom-control custom-text">
                                <label
                                  className="custom-control-label"
                                  htmlFor="purchase"
                                >
                                  Expense
                                </label>
                                <input
                                  type="text"
                                  className="form-control mt-3"
                                  id="purchase"
                                  name="purchase"
                                  required
                                  value={invoicesNumber.purchase || ""}
                                  onChange={(e) =>
                                    handleInputChangeWithConditonTypeNumber(
                                      e,
                                      /^[0-9\b]+$/
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col">
                              <div className="custom-control custom-text">
                                <label
                                  className="custom-control-label"
                                  htmlFor="quotation"
                                >
                                  Quotation
                                </label>
                                <input
                                  type="text"
                                  className="form-control mt-3"
                                  id="quotation"
                                  name="quotation"
                                  required
                                  value={invoicesNumber.quotation || ""}
                                  onChange={(e) =>
                                    handleInputChangeWithConditonTypeNumber(
                                      e,
                                      /^[0-9\b]+$/
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary btn-sm mt-3 px-3 py-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
