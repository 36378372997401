import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const nextYear = currentYear + 1;

const fiscalYearLabel = `FY ${currentYear}-${nextYear}`;
const fiscalYearValue = String(currentYear).slice(-2);

let FYear = {
  label: fiscalYearLabel,
  value: fiscalYearValue,
};
axios.defaults.headers["fy_id"] = FYear?.value;

const initialState = {
  FYear,
  FinancialYearArray: [FYear],
};

const FinancialYearSlice = createSlice({
  name: "FinancialYear",
  initialState,
  reducers: {
    setFinancialYearArray(state, action) {
      state.FinancialYearArray = action.payload;
    },
    editFinancialYear(state, action) {
      state.FYear = action.payload;
      axios.defaults.headers["fy_id"] = action.payload?.value;
    },
  },
});
export const { editFinancialYear, setFinancialYearArray } =
  FinancialYearSlice.actions;
export default FinancialYearSlice.reducer;
