import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { quotationApi } from "../../../assets/apis"; // assuming this is where your quotation API resides
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import Normal from "../../../printsLayouts/Quotation/Normal/Normal";
import Thermal from "../../../printsLayouts/Quotation/Thermal/Thermal";
import { setQuotationData } from "../../../redux-store/invoice";
import { dateFormateDDMMYYYY, formatNumberWithIndianCommas, hasPermission } from "../../../utils/helpers";
const Quotations = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);
  const { QuotationData } = useSelector((store) => store.Invoice);
  const { companyData } = useSelector((state) => state.Company);
  const [apiInProccess, setApiInProccess] = useState(false);

  const [allQuotations, setAllQuotations] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const printRef = useRef();

  const [openPortal, setOpenPortal] = useState(false);
  const [openMailModal, setOpenMailModal] = useState(false);
  const [mailData, setMailData] = useState({ email: "", quotation: "" });
  const [deletingItem, setDeletingItem] = useState(null);
  const [isCancel, setIsCancel] = useState(null);
  const [filters, setFilters] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const [queryParams, setQueryParams] = useState({ page: 1, pageSize: 10 });

  //send email
  const sendEmail = (data) => {
    const { checkout_details } = data;
    setMailData((pre) => ({
      ...pre,
      email: checkout_details?.email ? checkout_details?.email : "",
      quotation: data?.quotation,
    }));
    handleOpenMailModal();
  };

  const handleOpenMailModal = () => {
    setOpenMailModal(true);
  };

  const handleCloseMailModal = () => {
    setMailData({ email: "", quotation: "" });
    setOpenMailModal(false);
  };

  const handleSubmitMail = async (e) => {
    e.preventDefault();
    const { email, quotation } = mailData;
    if (!email || !(email && email.trim()))
      return toast.error("Please enter a valid email");
    const payload = { ...(email && { email: email.trim() }), quotation };
    setApiInProccess(true);

    try {
      const response = await axios.post(
        `${quotationApi}/post_quotation_mail`,
        payload
      );
      const result = response.data;
      toast.success(result?.message);
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
    } finally {
      setApiInProccess(false);

      handleCloseMailModal();
    }
  };

  //quotation delete logic
  const deleteQuotation = async (id) => {
    handleClosePortal();
    try {
      await axios.delete(`${quotationApi}/singleinv/${id}`);
      toast.success("Quotation deleted successfully");
      refetch();
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      handleApiError(error);
    }
  };

  const handleCancel = async (id) => {
    try {
      await axios.patch(`${quotationApi}/cancel_quotation/${id}`, {
        is_cancelled: true,
      });
      toast.success("Quotation cancelled successfully");
      refetch();
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      if (error.response?.data?.message)
        return toast.error(error.response?.data?.message);
      else toast.error("something went wrong ");
    } finally {
      handleClosePortal();
    }
  };

  const onClickPdfHandler = (data) => {
    dispatch(setQuotationData(data));
  };

  const printBillHandler = useReactToPrint({
    content: () => printRef.current,
    documentTitle: companyData?.quotation_prefix
      ? `${companyData?.quotation_prefix}${QuotationData?.quotation}`
      : `QUA-${QuotationData?.quotation}`,
    onBeforeGetContent: () => {},
    onBeforePrint: () => {},
    onAfterPrint: () => {
      dispatch(setQuotationData(null));
    },
    onPrintError: () => {},
  });

  useEffect(() => {
    if (QuotationData) {
      printBillHandler();
    }
  }, [QuotationData]);

  const handleApiError = (error) => {
    const errorMessage = error.response?.data?.message || "An error occurred";
    toast.error(errorMessage);
  };

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };

  const handleOpenIsCancelPortal = (data) => {
    setIsCancel(data);
    setOpenPortal(true);
  };

  const handleClosePortal = () => {
    setIsCancel(null);
    setDeletingItem(null);
    setOpenPortal(false);
  };

  //fetch logic
  const fetchQuotations = async ({ queryKey }) => {
    const [, queryParamsData] = queryKey;
    const queryParams = new URLSearchParams(queryParamsData);
    try {
      const response = await axios.get(`${quotationApi}?${queryParams}`);
      const { quotations, totalPages } = response.data;
      setAllQuotations(quotations || []);
      setTotalPages(totalPages || 1);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 403) return navigate("/upgrade");
      handleApiError(error);
    }
  };

  const { isError, error, refetch } = useQuery({
    queryKey: ["quotations", queryParams],
    queryFn: fetchQuotations,
    retry: false,
  });

  //filter search logic
  const onChangeFilterHandler = (e) => {
    const { name, value } = e.target;
    setFilters((pre) => ({ ...pre, [name]: value }));
  };

  const onChangeFilterHandlerWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputChangeWithConditonTypeNumber = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setFilters((prev) => ({ ...prev, [name]: Number(value) }));
    }
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    const { startDate, endDate } = filters;
    if (startDate && endDate && startDate > endDate)
      return toast.error("Start date must be less than or equal to end date");
    setQueryParams((pre) => ({ ...pre, ...filters, ...pagination }));
  };

  const onResetHandler = () => {
    setFilters({});
    setQueryParams((pre) => ({ page: 1, pageSize: 10 }));
    setPagination({ page: 1, pageSize: 10 });
  };

  useEffect(() => {
    setQueryParams((pre) => ({ ...pre, ...pagination }));
  }, [pagination.page, pagination.pageSize, pagination]);

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Invoices" }));
    dispatch(setSiderBarActiveLink({ link: "quotation" }));
  }, []);

  useEffect(() => {
    if (isError && error?.response?.status === 403) {
      navigate("/upgrade");
    }
  }, [isError, error, navigate]);
  return (
    <div className="main-panel mail_container ">
      <div className="content-wrapper">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title">
              {" "}
              All Quotations{" "}
              <i
                className="fa fa-info-circle text-secondary hover-cursor"
                data-toggle="tooltip"
                title="Send quotation to your potential clients with a proposed price for the goods or
                services"
                aria-hidden="true"
                data-original-title="Contacts who can become our Future clients"
              ></i>
            </div>
            {hasPermission(loggedUser, 37) && (
              <button
                className="btn btn-primary d-flex justify-content-center align-items-center px-2 py-1"
                onClick={() => navigate("/invoices/add/quotation")}
              >
                {/* <i className="mdi  mdi-receipt me-1"></i> */}
                Add Quotation
              </button>
            )}
          </div>
          <form className="row" onSubmit={onSearchHandler}>
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name">
                  Customer Name
                </label>
                <input
                  type="test"
                  className="form-control form-control-alternative"
                  name="name"
                  id="name"
                  value={filters.name || ""}
                  onChange={(e) =>
                    onChangeFilterHandlerWithCondition(e, /^[\s\S]*$/)
                  }
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="quotation">
                  Quotation Number
                </label>
                <input
                  type="text"
                  className="form-control form-control-alternative"
                  name="quotation"
                  id="quotation"
                  value={filters.quotation || ""}
                  onChange={(e) =>
                    handleInputChangeWithConditonTypeNumber(e, /^[0-9\b]+$/)
                  }
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="startDate">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-alternative"
                  name="startDate"
                  id="startDate"
                  value={filters.startDate || ""}
                  onChange={onChangeFilterHandler}
                />
              </div>
            </div>

            <div className="col-lg-2">
              <div className="form-group">
                <label className="form-control-label" htmlFor="endDate">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-alternative"
                  name="endDate"
                  id="endDate"
                  value={filters.endDate || ""}
                  onChange={onChangeFilterHandler}
                />
              </div>
            </div>
            <div
              className="col-lg-2 text-right"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div className="form-group mt-1">
                <button
                  type="submit"
                  id="search"
                  className="btn btn-primary btn-sm mt-4"
                >
                  search
                </button>
              </div>
              <div className="form-group mt-1">
                <button
                  type="button"
                  id="reset"
                  className="btn btn-primary btn-sm mt-4"
                  onClick={onResetHandler}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>

          <div className="d-flex flex-column align-items-center datatable">
            <DataTable
              value={allQuotations}
              style={{ width: "100%" }}
              id="quotation-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "4rem",
                }}
                body={(rowData, { rowIndex }) => (
                  <p>
                    {pagination.page * pagination.pageSize -
                      pagination.pageSize +
                      rowIndex +
                      1}
                  </p>
                )}
              />
              <Column
                className="border-bottom border-top"
                field="quotation"
                header="INVOICE"
                body={(rowData) =>
                  companyData?.quotation_prefix
                    ? `${companyData.quotation_prefix}${rowData.quotation}`
                    : `QUA-${rowData.quotation}`
                }
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  maxWidth: "8rem",
                  minWidth: "8rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="date"
                header="DATE"
                body={(data) => dateFormateDDMMYYYY(data.date)}
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              {/* <Column
                className="border-bottom border-top"
                field="amount"
                header="AMOUNT"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "center",
                  minWidth: "6rem",
                }}
              /> */}
              {/* <Column
                className="border-bottom border-top"
                field="total_tax"
                header="TAX"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "center",
                  minWidth: "6rem",
                }}
              /> */}
              {/* <Column
                className="border-bottom border-top"
                field="discount"
                header="DISCOUNT"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "center",
                  minWidth: "6rem",
                }}
              /> */}
              <Column
                className="border-bottom border-top"
                field="total"
                header="TOTAL"
                body={(data) =>
                  formatNumberWithIndianCommas(data.total || 0)
                }
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="is_cancelled"
                header="STATUS"
                body={(data) =>
                  data.is_cancelled ? (
                    <span style={{ color: "#ffaf00" }} className="p-1">
                      Cancelled
                    </span>
                  ) : (
                    "--"
                  )
                }
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                }}
              />

              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      display: "flex",
                    }}
                  >
                    {data?.is_cancelled ? (
                      ""
                    ) : companyData?.mailPermissions?.quotation ? (
                      <i
                        className="dropdown-item-icon mdi mdi-email text-primary me-2 hover-cursor"
                        title="Send Invoice"
                        onClick={() => sendEmail(data)}
                      ></i>
                    ) : (
                      ""
                    )}

                    <i
                      style={{ cursor: "pointer" }}
                      className="dropdown-item-icon mdi mdi-information text-primary me-2"
                      onClick={(e) => {
                        navigate(`/invoices/view/quotation/${data.quotation}`);
                      }}
                      title="View Invoice"
                    />

                    <i
                      style={{ cursor: "pointer" }}
                      className="dropdown-item-icon mdi mdi-printer text-primary me-2"
                      onClick={() => onClickPdfHandler(data)}
                      title="Print"
                    />

                    {data?.is_cancelled
                      ? ""
                      : hasPermission(loggedUser, 38) && (
                          <i
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              navigate(
                                `/invoices/edit/quotation/${data.quotation}`
                              );
                            }}
                            className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                            title="Edit Invoice"
                          />
                        )}

                    {/* {hasPermission(loggedUser, 39) && (
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleOpenPortal(data);
                        }}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                        title="Delete Invoice"
                      />
                    )} */}
                    {data?.is_cancelled ? (
                      ""
                    ) : (
                      <i
                        className="dropdown-item-icon mdi mdi-cancel text-primary me-2 hover-cursor"
                        title="Cancel"
                        onClick={() => handleOpenIsCancelPortal(data)}
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
            <div
              className=""
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-evenly",
                margin: "10px 0 0 0",
              }}
            >
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === 1}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page - 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-left" />
              </button>
              <span>
                Page {pagination.page} of {totalPages}
              </span>
              <button
                style={{ border: "none", fontSize: "24px" }}
                disabled={pagination.page === totalPages}
                onClick={() =>
                  setPagination((prev) => {
                    return { ...prev, page: prev.page + 1 };
                  })
                }
              >
                <i className="mdi mdi-chevron-right" />
              </button>
              <select
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination((prev) => {
                    return { ...prev, pageSize: e.target.value };
                  })
                }
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"Quotation Invoice"}
          isCancel={isCancel}
          name={
            companyData?.quotation_prefix
              ? `${companyData?.quotation_prefix}${
                  isCancel ? isCancel.quotation : deletingItem?.quotation
                }`
              : `QUA-${
                  isCancel ? isCancel.quotation : QuotationData?.quotation
                }`
          }
          id={isCancel ? isCancel.id : deletingItem.id}
          handleDalete={deleteQuotation}
          handleCancel={handleCancel}
        ></ConfirmPortal>
      )}

      {/* mail model */}
      <div
        className={`modal fade ${
          openMailModal ? "show active-modal" : "deactive-modal"
        }`}
        id="mailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mailModalLabel"
        aria-modal="true"
        onClick={handleCloseMailModal}
      >
        <div
          className="modal-dialog"
          role="document"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="mailModalLabel">
                Send Invoice
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseMailModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={handleSubmitMail}>
              <div className="modal-body">
                Confirm Email
                <input
                  type="email"
                  className="form-control form-control-alternative mt-2"
                  required
                  id="email"
                  name="email"
                  value={mailData.email || ""}
                  onChange={(e) =>
                    setMailData((pre) => ({
                      ...pre,
                      [e.target.name]: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleCloseMailModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  id="send_button"
                  className="btn btn-primary btn-sm"
                  disabled={apiInProccess}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {companyData?.pdfLayout === "normal" ? (
        <div style={{ display: "none" }}>
          <Normal printRef={printRef} />
        </div>
      ) : (
        <div style={{ display: "none" }}>
          <Thermal printRef={printRef} />
        </div>
      )}
    </div>
  );
};

export default Quotations;
