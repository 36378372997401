import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { state } from "../../assets/StateList";
import { countryList } from "../../assets/countryList";
import { useSelector } from "react-redux";
import NoImage from "../../assets/images/logos/no-pictures.png";

import { selectCustomStyle } from "../../assets/selectCustomStyle";

function ViewCompany() {
  const { companyData } = useSelector((state) => state.Company);

  const navigate = useNavigate();
  const [company, setCompany] = useState({});
  const [check, setCheck] = useState({});
  const [logo, setLogo] = useState({ show: null, send: null });
  const [sign, setSign] = useState({ show: null, send: null });
  const [commonSeal, setCommonSeal] = useState({ show: null, send: null });
  const isCountryIndia = company.country === "India";

  useMemo(() => {
    if (companyData) {
      setCompany({
        address: companyData.address,
        cin_no: companyData.cin_no,
        country: companyData.country,
        state: companyData.state,
        city: companyData.city,
        dl_no: companyData.dl_no,
        email: companyData.email,
        gst: companyData.gst,
        name: companyData.name,
        pan: companyData.pan,
        phone: companyData.phone,
        pin: companyData.pin,
        service_tax: companyData.service_tax,
        tin: companyData.tin,
        website: companyData.website,
        upi: companyData.upi,
        sales_inv_prefix: companyData.sales_inv_prefix,
        purchase_inv_prefix: companyData.purchase_inv_prefix,
        additional_detail: companyData.additional_detail,
        running_out_limit: companyData.running_out_limit,
        quotation_prefix: companyData.quotation_prefix,
        bussiness_type: companyData.bussiness_type,
        e_commerce_gst: companyData.e_commerce_gst,
      });
      setLogo({ show: companyData.logo, send: null });
      setSign({ show: companyData.sign, send: null });
      setCommonSeal({ show: companyData.common_seal, send: null });
      setCheck({
        is_bank_detail: companyData.is_bank_detail,
        is_common_seal: companyData.is_common_seal,
        is_logo: companyData.is_logo,
        is_sign: companyData.is_sign,
      });
      return;
    }
  }, [companyData]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline ">
              <h3 className="ukhd mb-3">View Company</h3>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <form className="forms-sample">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> Company Name *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="Company Name"
                              name="name"
                              defaultValue={company.name || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> Email Address *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="Email Address"
                              name="email"
                              defaultValue={company.email || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Website </label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="Website"
                              name="website"
                              defaultValue={company.website || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Phone *</label>
                            <div className="input-group mb-3">
                              <div className="input-group-append">
                                <span className="input-group-text mdi mdi-phone h-100"></span>
                              </div>
                              <input
                                type="number"
                                className="form-control h-100"
                                placeholder="Phone"
                                name="phone"
                                defaultValue={company.phone || ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="form-group">
                            <label> Address *</label>
                            <textarea
                              type="text"
                              className="form-control"
                              style={{ height: "47px" }}
                              placeholder="Address"
                              name="address"
                              defaultValue={company.address || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="select-container ">
                            <label className="col-lg-12 margin-bottom-dropdown mb-2">
                              {" "}
                              Country *
                            </label>
                            <Select
                              required
                              name="country"
                              className=""
                              value={{
                                value: company.country || "",
                                label: company.country || "",
                              }}
                              isDisabled
                              options={countryList.map((country) => ({
                                label: country,
                                value: country,
                              }))}
                              styles={selectCustomStyle}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="select-container ">
                            <label className="col-lg-12 margin-bottom-dropdown mb-2">
                              {" "}
                              State *
                            </label>
                            {isCountryIndia ? (
                              <Select
                                name="state"
                                className="w-100"
                                required
                                value={{
                                  value: company.state || "",
                                  label: company.state || "",
                                }}
                                options={state.map((state) => ({
                                  label: state,
                                  value: state,
                                }))}
                                isDisabled
                                styles={selectCustomStyle}
                              />
                            ) : (
                              <input
                                defaultValue={company.state || ""}
                                readOnly
                                name="state"
                                type="text"
                                className="form-control"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> City *</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control h-100"
                                placeholder="City"
                                name="city"
                                defaultValue={company.city || ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> PIN *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="PIN"
                              value={company.pin || ""}
                              name="pin"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>UPI Id</label>
                            <input
                              className="form-control h-100"
                              name="upi"
                              placeholder="Enter UPI id"
                              defaultValue={company.upi || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> Invoice Color</label>
                            <div className="input-group">
                              <input
                                type="color"
                                className="form-control h-100 form-control-color"
                                id="exampleColorInput"
                                name="color"
                                defaultValue={company.color || ""}
                                readOnly
                                title="Choose your color"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 mb-2">
                          <div className="form-group">
                            <img
                              src={
                                logo.show ||
                                NoImage
                              }
                              style={{ width: "180px", height: "130px" }}
                              className="img-thumbnail img-fluid"
                              alt=""
                            />
                          </div>
                          <input
                            type="checkbox"
                            className="checkbox margin-between"
                            name="is_logo"
                            checked={check.is_logo}
                            readOnly
                          />{" "}
                          <label className="mb-2">
                            Is Logo Printed on Invoice{" "}
                          </label>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="form-group">
                            <img
                              src={
                                sign.show ||
                                NoImage
                              }
                              style={{ width: "140px", height: "130px" }}
                              className="img-thumbnail img-fluid"
                              alt=""
                            />
                          </div>
                          <input
                            type="checkbox"
                            className="checkbox margin-between"
                            name="is_sign"
                            checked={check.is_sign}
                            readOnly
                          />{" "}
                          <label>Is Signature Printed on Invoice</label>
                        </div>

                        <div className="col-lg-12  margin-vertical">
                          <div className="border"></div>
                        </div>

                        <div className="col-lg-12  margin-vertical">
                          <div className="form-group m-0 p-0">
                            <label>Company Information</label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> TIN *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="TIN"
                              name="tin"
                              defaultValue={company.tin || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label> GSTIN *</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="GSTIN"
                              name="gst"
                              maxLength="15"
                              minLength="15"
                              pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                              title="GSTIN should be in the format: 11POPLP4627X3Z2"
                              defaultValue={company.gst || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Service Tax No.</label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control h-100"
                                placeholder="Service Tax No."
                                name="service_tax"
                                defaultValue={company.service_tax || ""}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>PAN *</label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control h-100"
                                placeholder="PAN"
                                name="pan"
                                maxLength="10"
                                minLength="10"
                                pattern="[A-Z]{5}[0-9]{4}[A-Z]"
                                defaultValue={company.pan}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>D.L.No. </label>
                            <input
                              type="text"
                              className="form-control h-100"
                              placeholder="D.L.No."
                              name="dl_no"
                              defaultValue={company.dl_no || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>CIN No.</label>
                            <input
                              className="form-control h-100"
                              name="cin_no"
                              placeholder="CIN No."
                              defaultValue={company.cin_no || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Additional Detail</label>
                            <textarea
                              className="form-control h-100"
                              name="additional_detail"
                              placeholder="Additional Details"
                              defaultValue={company.additional_detail || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Running Out Limit</label>
                            <input
                              type="number"
                              className="form-control h-100"
                              name="running_out_limit"
                              placeholder="Running Out Limit"
                              defaultValue={company.running_out_limit || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Expense Invoice Prefix</label>
                            <input
                              className="form-control h-100"
                              name="purchase_inv_prefix"
                              placeholder="Purchase Invoice Prefix"
                              defaultValue={company.purchase_inv_prefix || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Sales Invoice Prefix</label>
                            <input
                              className="form-control h-100"
                              name="sales_inv_prefix"
                              placeholder="Sales Invoice Prefix"
                              defaultValue={company.sales_inv_prefix || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Quotation Prefix</label>
                            <input
                              className="form-control h-100"
                              name="quotation_prefix"
                              placeholder="Quotation Prefix"
                              defaultValue={company.quotation_prefix || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Type of Bussiness *</label>
                            <input
                              className="form-control h-100"
                              name="bussiness_type"
                              placeholder="Type of Bussiness"
                              defaultValue={""}
                              value={company.bussiness_type}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <img
                              src={
                                commonSeal.show ||
                                NoImage
                              }
                              style={{ width: "60%" }}
                              alt=""
                            />
                          </div>
                          <div className="form-group mt-4">
                            <input
                              type="checkbox"
                              className="checkbox margin-between"
                              name="is_common_seal"
                              checked={check.is_common_seal}
                              readOnly
                            />{" "}
                            <label>Is Common Seal Printed on Invoice</label>
                          </div>
                        </div>
                        <div className="col-lg-12  margin-vertical">
                          <div className="form-group m-0 p-0">
                            <label>AGGREGATE TURNOVER</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>E Commerce GST</label>
                            <input
                              type="text"
                              className="form-control h-100"
                              name="e_commerce_gst"
                              placeholder="E Commerce GST"
                              defaultValue={company.e_commerce_gst || ""}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              className="checkbox margin-between"
                              name="is_bank_detail"
                              checked={check.is_bank_detail}
                              readOnly
                            />{" "}
                            <label>Bank Details</label>
                            <br />
                            (Will be Printed on Normal Invoice)
                          </div>
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn btn-secondary"
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCompany;
