import React, { useEffect, useMemo, useState } from "react";
import { selectStyle } from "../../assets/selectCustomStyle";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../spinner/first_spinner/Spinner";
import { inventoryApi } from "../../assets/apis";
import CsvDownloader from "react-csv-downloader";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import axios from "axios";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import {
  dateFormateDDMMYYYY,
  parseFloatWithFixedValue,
} from "../../utils/helpers";
import usePagination from "../../Pagination/Pagination";
import CustomPagination from "../../Pagination/CustomPagination";
import { tableStyle, tdThStyle } from "../../Pagination/tableStyle";
import { Link, useNavigate } from "react-router-dom";
import CoverSpin from "../../spinner/second_spinner/CoverSpin";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";
import ResultNotFound from "../../notFound/ResultNotFound/ResultNotFound";

const initailSearchQuery = {
  product_name: "",
  customer_name: "",
  startDate: "",
  endDate: "",
  contactType: "",
  productType: "",
  documentType: "",
};
const InventoryReports = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { companyData } = useSelector((store) => store.Company);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [searchQueryParams, setSearchQueryParams] =
    useState(initailSearchQuery);
  const [queryParams, setQueryParams] = useState(initailSearchQuery);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchQueryParams((pre) => ({ ...pre, [name]: value }));
  };

  const handleOnExportExcel = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(
      mapData(_DATA.currentData(), companyData)
    );
    XLSX.utils.book_append_sheet(wb, ws, "inventory_report");
    XLSX.writeFile(wb, "inventory_report.xlsx");
    setShow((pre) => !pre);
  };

  const fetchInventories = async ({ queryKey }) => {
    const [
      ,
      {
        product_name,
        customer_name,
        startDate,
        endDate,
        contactType,
        productType,
        documentType,
      },
    ] = queryKey;
    const queryParams = new URLSearchParams({
      product_name,
      customer_name,
      startDate,
      endDate,
      contactType,
      productType,
      documentType,
    });
    const response = await axios.get(`${inventoryApi}?${queryParams}`);
    return response.data;
  };

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["inventories", queryParams],
    queryFn: fetchInventories,
    retry: false,
  });
  const _DATA = usePagination(data?.inventories || []);

  const handleChange = (e, p) => {
    _DATA.jump(p);
  };

  const handleOnChangePerPage = (e) => {
    const { value } = e.target;
    _DATA.showPerPage(value);
  };

  const onSearchHandler = (e) => {
    e.preventDefault();
    const { startDate, endDate } = searchQueryParams;
    if (startDate && endDate && startDate > endDate)
      return toast.error("Start date must be less than or equal to end date");
    setQueryParams((pre) => ({ ...pre, ...searchQueryParams }));
  };

  const onClearHandler = (e) => {
    setSearchQueryParams(initailSearchQuery);
    setQueryParams(initailSearchQuery);
  };

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  // if (isLoading) {
  //   return (
  //     <div className="main-panel">
  //       <div className="content-wrapper">
  //         <SecondSpinner />
  //       </div>
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (isError && error?.response?.status === 403) {
      navigate("/upgrade");
    }
  }, [isError, error, navigate]);

  if (isError) {
    return (
      <div>{error?.response?.data?.message || "Something went wrong"}</div>
    );
  }
  return (
    <>
      {isLoading && <Spinner />}
      <div className="col-12 h-5">
        <form className="row" onSubmit={onSearchHandler}>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="form-control-label" htmlFor="product_name">
                Item Name
              </label>
              <input
                type="text"
                className="form-control form-control-alternative"
                name="product_name"
                id="product_name"
                value={searchQueryParams.product_name}
                onChange={handleInputChange}
              />
            </div>

            <div id="product-result-box">
              <ul className="list-group mt--4" id="product-result"></ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="form-control-label" htmlFor="">
                Customer Name
              </label>
              <input
                type="test"
                className="form-control form-control-alternative"
                name="customer_name"
                id="customer_name"
                value={searchQueryParams.customer_name}
                onChange={handleInputChange}
              />
            </div>

            <div id="result-box">
              <ul className="list-group mt--4" id="result"></ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="form-control-label" htmlFor="startDate">
                Start Date
              </label>
              <input
                type="date"
                className="form-control form-control-alternative"
                name="startDate"
                id="startDate"
                value={searchQueryParams.startDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="form-control-label" htmlFor="endDate">
                End Date
              </label>
              <input
                type="date"
                className="form-control form-control-alternative"
                name="endDate"
                id="endDate"
                value={searchQueryParams.endDate}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="form-group">
              <label className="form-control-label" htmlFor="productType">
                Item Type
              </label>
              <select
                id="productType"
                name="productType"
                className="form-control form-control-alternative"
                style={selectStyle}
                value={searchQueryParams.productType || ""}
                onChange={handleInputChange}
              >
                <option value="">-&nbsp;-select-&nbsp;-</option>
                <option value="products">Product</option>
                <option value="services">Service</option>
              </select>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <label className="form-control-label" htmlFor="documentType">
                Invoice Type
              </label>
              <select
                id="documentType"
                name="documentType"
                className="form-control form-control-alternative"
                style={selectStyle}
                value={searchQueryParams.documentType || ""}
                onChange={handleInputChange}
              >
                <option value="">-&nbsp;-select-&nbsp;-</option>
                <option value="sales">Sales</option>
                <option value="expense">Expense</option>
              </select>
            </div>
          </div>

          <div className="col-lg-6 ">
            <div
              className="form-group mb-0 mt-3"
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <button
                type="submit"
                id="search_Inventory_reports"
                className="btn btn-primary btn-sm mt-4"
              >
                Search
              </button>
              <button
                type="button"
                id="reset"
                className="btn btn-primary btn-sm mt-4"
                onClick={onClearHandler}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm dropdown-toggle mt-4"
                disabled={_DATA.currentData().length === 0}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setShow((pre) => !pre)}
              >
                Download
              </button>
            </div>
            <div
              className={`dropdown-menu ${show && "show"}`}
              style={{ right: 0 }}
            >
              {/* <span
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  id="pdf"
                  onClick={handleOnExportPDF}
                >
                  Export PDF
                </span> */}
              <CSVLink
                data={mapData(_DATA.currentData(), companyData)}
                filename={"Inventory_reports.csv"}
                // target="_blank"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <span
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  id="csv"
                  onClick={() => setShow((pre) => !pre)}
                >
                  Export CSV
                </span>
              </CSVLink>

              <span
                className="dropdown-item cursor-pointer"
                style={{ cursor: "pointer" }}
                id="excel"
                onClick={handleOnExportExcel}
              >
                Export XLS
              </span>
              {/* <!-- <a className="dropdown-item" href="#">Print</a> --> */}
            </div>
          </div>
        </form>
      </div>
      {_DATA.currentData().length === 0 ? (
        <ResultNotFound message={`Sorry, we counldn't find any results`} />
      ) : (
        <div className="table-responsive mt-4">
          <label className="mb-3">
            Show{" "}
            <select
              name="example_length"
              aria-controls="example"
              className=""
              onChange={handleOnChangePerPage}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={_DATA.length}>All</option>
            </select>{" "}
            entries
          </label>
          <div style={{ overflowX: "auto", scrollbarWidth: "thin" }}>
            <table
              className="table align-items-center table-flush"
              id="inventory_table"
              style={{ ...tableStyle }}
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    colSpan={1}
                    style={{ ...tdThStyle, width: "70px" }}
                  >
                    Sr. No.
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "180px" }}>
                    Item Name
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "180px" }}>
                    Customer Name
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "82px" }}>
                    Invoice
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "94px" }}>
                    Date
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "106px" }}>
                    Invoice Type
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "90px" }}>
                    Item Type
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "92px" }}>
                    Qty
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "100px" }}>
                    Unit Price
                  </th>
                  <th scope="col" style={{ ...tdThStyle, width: "100px" }}>
                    Sale Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {_DATA.currentData()?.map?.((inventory, index) => (
                  <tr key={inventory.id}>
                    <td colSpan={1} style={{ ...tdThStyle, width: "70px" }}>
                      {(_DATA.currentPage - 1) * _DATA.perPage + (index + 1)}
                    </td>
                    <td style={{ ...tdThStyle, width: "180px" }}>
                      {inventory?.product_name}
                    </td>
                    <td style={{ ...tdThStyle, width: "180px" }}>
                      {inventory?.customer_name}
                    </td>
                    <td style={{ ...tdThStyle, width: "82px" }}>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={
                          inventory?.document === 1
                            ? `/invoices/view/sales/${inventory?.invoice_no}`
                            : `/invoices/view/expenses/${inventory?.invoice_no}`
                        }
                        target="_blank"
                      >
                        {inventory?.document === 1
                          ? companyData?.sales_inv_prefix
                            ? `${companyData.sales_inv_prefix}${inventory?.invoice_no}`
                            : `INV-${inventory?.invoice_no}`
                          : companyData?.purchase_inv_prefix
                          ? `${companyData.purchase_inv_prefix}${inventory?.invoice_no}`
                          : `EXP-${inventory?.invoice_no}`}
                      </Link>
                    </td>
                    <td style={{ ...tdThStyle, width: "94px" }}>
                      {dateFormateDDMMYYYY(inventory?.date)}
                    </td>
                    <td style={{ ...tdThStyle, width: "106px" }}>
                      {inventory?.document === 1 ? "Sales" : "Expense"}
                    </td>
                    <td style={{ ...tdThStyle, width: "90px" }}>
                      {inventory?.type === 1 ? "Product" : "Service"}
                    </td>
                    <td style={{ ...tdThStyle, width: "92px" }}>
                      {inventory?.quantity}
                    </td>
                    <td style={{ ...tdThStyle, width: "100px" }}>
                      ₹ {inventory?.unit_price}
                    </td>
                    <td style={{ ...tdThStyle, width: "100px" }}>
                      ₹ {inventory?.sale_price}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={7} style={{ ...tdThStyle }}></td>

                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Qty)</span>
                    <br />
                    <span>
                      {_DATA
                        .currentData()
                        .reduce(
                          (total, inventory) =>
                            total + Number(inventory.quantity),
                          0
                        )}
                    </span>
                  </td>
                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Price)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce(
                            (total, inventory) =>
                              total +
                              Number(inventory.quantity) *
                                Number(inventory.unit_price),
                            0
                          )
                      )}
                    </span>
                  </td>
                  <td
                    style={{
                      ...tdThStyle,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                    }}
                  >
                    <span style={{ color: "gray" }}>(Total Sales)</span>
                    <br />
                    <span>
                      ₹&nbsp;
                      {parseFloatWithFixedValue(
                        _DATA
                          .currentData()
                          .reduce(
                            (total, inventory) =>
                              total +
                              Number(inventory.quantity) *
                                Number(inventory.sale_price),
                            0
                          )
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-2">
            <CustomPagination
              count={_DATA.maxPage}
              page={_DATA.currentPage}
              length={_DATA.length}
              currentDataLength={_DATA.currentData().length}
              onChange={handleChange}
              perPage={_DATA.perPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default InventoryReports;

function mapData(invoices, companyData) {
  return invoices.map(
    (
      {
        customer_name,
        date,
        document,
        id,
        invoice_no,
        product_name,
        quantity,
        sale_price,
        type,
        unit_price,
      },
      index
    ) => ({
      ["Sr. No."]: index + 1,
      ["Invoice"]:
        document === 1
          ? companyData?.sales_inv_prefix
            ? `${companyData.sales_inv_prefix}${invoice_no}`
            : `INV-${invoice_no}`
          : companyData?.purchase_inv_prefix
          ? `${companyData.purchase_inv_prefix}${invoice_no}`
          : `EXP-${invoice_no}`,
      ["Customer Name"]: customer_name,
      Date: dateFormateDDMMYYYY(date),
      ["Item Name"]: product_name,
      ["Item Type"]: type === 1 ? "Product" : "Service",
      ["Invoice Type"]: document === 1 ? "Sales" : "Expense",
      Quantity: quantity,
      ["Unit Price"]: unit_price,
      ["Sale Price"]: sale_price,
    })
  );
}
