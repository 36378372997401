import React, { useEffect, useRef, useState } from "react";
import clients_demo_file from "../../assets/template/clients_demo_file.csv";
import leads_demo_file from "../../assets/template/leads_demo_file.csv";
import items_demo_file from "../../assets/template/items_demo_file.csv";
import { contactApi, productApi, leadApi } from "../../assets/apis";
import axios from "axios";
import { hasPermission } from "../../utils/helpers";
import { useSelector } from "react-redux";

const informativeMessage = {
  contacts:
    "Contacts with Unique Email and Phone Number will only be imported.",
  items: "Items with Unique Name will only be imported.",
  leads: "Leads with Unique Email and Phone Number will only be imported.",
};

const apis = {
  contacts: contactApi,
  items: productApi,
  leads: leadApi,
};

const demoFiles = {
  contacts: clients_demo_file,
  leads: leads_demo_file,
  items: items_demo_file,
};

const ImportData = () => {
  const { loggedUser } = useSelector((state) => state.Auth);

  const navItems = [
    { type: "contacts", permission: 4 },
    { type: "items", permission: 14 },
    { type: "leads", permission: 9 },
  ]
    .filter((item) => hasPermission(loggedUser, item.permission))
    .map((item) => item.type);

  const [fileData, setFileData] = useState(null);
  const [importDataType, setImportDataType] = useState(navItems[0]);
  const [message, setMessage] = useState({ content: "", type: "" });
  const [apiInProccess, setApiInProccess] = useState(false);

  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };

  const handleInputChange = (e) => {
    setFileData(e.target.files[0]);
  };

  const handleDownloadCsvTemplate = () => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = demoFiles[importDataType];
    a.download = `${importDataType}_demo_file.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fileData) return;
    setApiInProccess(true);

    try {
      const data = new FormData();
      data.append("file", fileData);
      const response = await axios.post(`${apis[importDataType]}/import`, data);
      setFileData(null);
      reset();
      setMessage({ content: response.data.message, type: "success" });
    } catch (error) {
      setMessage({
        content:
          error.response?.data?.message ||
          "Something went wrong. Please ensure the uploaded file matches the demo file format.",
        type: "error",
      });
    } finally {
      setApiInProccess(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => setMessage({ content: "", type: "" }),
      5000
    );
    return () => clearTimeout(timeout);
  }, [message.content]);

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="d-flex justify-content-between m-1 p-1 align-items-baseline">
              <h3 className="ukhd mb-3">Import Data</h3>
            </div>
            <div className="row flex-grow">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    {message.content && (
                      <div
                        className={`alert ${
                          message.type === "error"
                            ? "alert-warning"
                            : "alert-success"
                        }`}
                        role="alert"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {message.content}
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={() => setMessage({ content: "", type: "" })}
                        ></button>
                      </div>
                    )}

                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {navItems.map((type) => (
                        <li className="nav-item" role="presentation" key={type}>
                          <span
                            className={`nav-link ${
                              importDataType === type ? "active" : ""
                            }`}
                            onClick={() => setImportDataType(type)}
                          >
                            Import{" "}
                            {type.charAt(0).toUpperCase() + type.slice(1)} Data
                          </span>
                        </li>
                      ))}
                    </ul>

                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active mb-4"
                        id="clients"
                        role="tabpanel"
                        aria-labelledby="clients-tab"
                      >
                        <form onSubmit={handleSubmit}>
                          <input
                            ref={ref}
                            accept=".csv"
                            type="file"
                            className="form-control form-control-alternative"
                            id="contact_import"
                            name="contact_import"
                            required
                            onChange={handleInputChange}
                          />
                          <small className="text-warning">
                            * {informativeMessage[importDataType]}
                          </small>
                          <div className="my-3">
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                              disabled={apiInProccess}
                            >
                              Upload
                            </button>
                            <span
                              className="btn btn-secondary btn-sm"
                              onClick={handleDownloadCsvTemplate}
                            >
                              Save Demo File
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportData;
