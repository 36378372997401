import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../redux-store/sidebarActiveList";
import { useDispatch, useSelector } from "react-redux";
import SecondSpinner from "../../spinner/third_spinner/SecondSpinner";
import AddCustomer from "../../assets/images/logos/AddCustomer.png";
import Invoices from "../../assets/images/logos/Invoices.png";
import AddItem from "../../assets/images/logos/AddItem.png";
import Reports from "../../assets/images/logos/Reports.png";
import checkmark from "../../assets/images/logos/checkmark.png";
import { expiryDetails } from "../../utils/helpers";
const MySubscription = () => {
  const { companyData } = useSelector((state) => state.Company);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: null }));
    dispatch(setSiderBarActiveLink({ link: null }));
  }, []);

  useEffect(() => {
    if (companyData && !isEmpty(companyData)) {
      setIsPageLoading(false);
    }
  }, [companyData]);

  const formattedExpiryDate = new Date(companyData?.expired).toLocaleString(
    "en-US",
    {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "long",
    }
  );

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        {isPageLoading ? (
          <SecondSpinner />
        ) : (
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-rounded">
                <div
                  className="card-body"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #FFC371, #FF5F6D)",
                    backgroundSize: "cover", // Makes the image cover the entire div
                    backgroundRepeat: "no-repeat",
                    borderRadius: "12px",
                  }}
                >
                  <div className="col-12" style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => navigate("/")}
                    ></button>
                  </div>
                  <div className="col-12"></div>

                  <div className="col-12" style={{ padding: "1rem 0rem" }}>
                    <div
                      style={{
                        background: "#E2DAD6",
                        padding: "1rem 2rem",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="col-12 d-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column gap-2">
                          <h2 className="m-0 " style={{ fontWeight: "bold" }}>
                            Subscription Details
                          </h2>
                          <h6 className="m-0" style={{ fontWeight: "bold" }}>
                            Here's what you get:
                          </h6>
                        </div>
                        {/* <div
          style={{
            border: "1px solid black",
            padding: "4px",
            borderRadius: "8px",
          }}
        >
          <span
            className="mdi mdi-gift mdi-36px hover-cursor"
            style={{ height: "36px" }}
          ></span>
        </div> */}
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-between mt-4">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <div
                            style={{
                              border: "1px solid black",
                              padding: "2px",
                              borderRadius: "8px",
                            }}
                          >
                            <img src={AddCustomer} alt="" width={60} />
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <h4 className="m-0 ">Customers</h4>
                            <h6 className="m-0">Add Unlimited Customers</h6>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "2px",
                          }}
                        >
                          <img src={checkmark} alt="" width={40} />
                        </div>
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-between mt-4">
                        <div className="d-flex align-items-center gap-2">
                          <div
                            style={{
                              border: "1px solid black",
                              padding: "2px",
                              borderRadius: "8px",
                            }}
                          >
                            <img src={AddItem} alt="" width={60} />
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <h4 className="m-0 ">Items</h4>
                            <h6 className="m-0">Add Unlimited Items</h6>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "2px",
                          }}
                        >
                          <img src={checkmark} alt="" width={40} />
                        </div>
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-between mt-4">
                        <div className="d-flex align-items-center gap-2">
                          <div
                            style={{
                              border: "1px solid black",
                              padding: "2px",
                              borderRadius: "8px",
                            }}
                          >
                            <img src={Invoices} alt="" width={60} />
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <h4 className="m-0 ">Invoices</h4>
                            <h6 className="m-0">Create Unlimited Invoices</h6>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "2px",
                          }}
                        >
                          <img src={checkmark} alt="" width={40} />
                        </div>
                      </div>
                      <div className="col-12 d-flex align-items-center justify-content-between mt-4">
                        <div className="d-flex align-items-center gap-2">
                          <div
                            style={{
                              border: "1px solid black",
                              padding: "2px",
                              borderRadius: "8px",
                            }}
                          >
                            <img src={Reports} alt="" width={60} />
                          </div>
                          <div className="d-flex flex-column gap-2">
                            <h4 className="m-0 ">Reports</h4>
                            <h6 className="m-0">Get All Reports</h6>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "2px",
                          }}
                        >
                          <img src={checkmark} alt="" width={40} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-4">
                    {expiryDetails(companyData?.expired)?.isExpired ? (
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        Your plan has expired. Please renew to continue using
                        our services.&nbsp;
                        <Link
                          to="/pick/plan"
                          style={{ textDecoration: "none" }}
                        >
                          Click Here
                        </Link>
                      </div>
                    ) : expiryDetails(companyData?.expired)?.remainingDays ===
                      1 ? (
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        Your plan expires today! Please renew immediately.&nbsp;
                        <Link
                          to="/pick/plan"
                          style={{ textDecoration: "none" }}
                        >
                          Click Here
                        </Link>
                      </div>
                    ) : expiryDetails(companyData?.expired)?.remainingDays ===
                      2 ? (
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        Your plan expires Tomorrow! Please renew
                        immediately.&nbsp;
                        <Link
                          to="/pick/plan"
                          style={{ textDecoration: "none" }}
                        >
                          Click Here
                        </Link>
                      </div>
                    ) : expiryDetails(companyData?.expired)?.remainingDays ===
                      3 ? (
                      <div style={{ color: "red", fontWeight: "bold" }}>
                        Your plan expire within{" "}
                        {expiryDetails(companyData?.expired)?.remainingDays}{" "}
                        days! Please renew immediately.&nbsp;
                        <Link
                          to="/pick/plan"
                          style={{ textDecoration: "none" }}
                        >
                          Click Here
                        </Link>
                      </div>
                    ) : (
                      <div style={{ color: "black", fontWeight: "bold" }}>
                        Your plan is active. Please keep an eye on the
                        expiration date: {formattedExpiryDate}.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MySubscription;

function isEmpty(obj) {
  return obj && Object.keys(obj).length === 0;
}
