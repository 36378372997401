import { configureStore } from "@reduxjs/toolkit";
import Auth from "./Auth";
import Invoice from "./invoice";
import FinancialYear from "./FinancialYear";
import sidebarActiveList from "./sidebarActiveList";
import PlanExpiry from "./PlanExpire";
import Company from "./Company";
const store = configureStore({
  reducer: {
    Auth: Auth,
    Invoice: Invoice,
    FinancialYear: FinancialYear,
    SidebarActiveList: sidebarActiveList,
    Company,
    PlanExpiry,
  },
});

export default store;
