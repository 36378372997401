import { Fragment } from "react";
import ReactDOM from "react-dom";

import "./ConfirmPortal.css";

const Backdrop = ({ onClick, children }) => {
  return (
    <div className="confirmbackdrop" onClick={onClick}>
      {children}
    </div>
  );
};

const ModalOverlay = ({
  children,
  onClick,
  type,
  name,
  id,
  handleDalete,
  handleCancel,
  isCancel,
}) => {
  return (
    <div className="confirmmodal">
      <div className="modal-content" style={{ border: "none" }}>
        <div className="modal-header" style={{ border: "none" }}>
          <h5 className="modal-title" id="exampleModalLabel">
            {isCancel ? "Cancel" : "Delete"}&nbsp;{type}
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onClick}
          ></button>
        </div>
        <div className="modal-body">
          <span id="modal_body">
            Do You Want to {isCancel ? "Cancel" : "Delete"}&nbsp;{name} ?
          </span>
        </div>
        <div className="modal-footer" style={{ border: "none" }}>
          <button className="btn btn-secondary btn-sm" onClick={onClick}>
            Cancel
          </button>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => (isCancel ? handleCancel(id) : handleDalete(id))}
          >
            {isCancel ? "Mark as Cancel" : "Delete"}&nbsp;
          </button>
        </div>
      </div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const ConfirmPortal = ({
  onClose,
  children,
  name,
  type,
  id,
  handleDalete,
  handleCancel,
  isCancel,
}) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClick={onClose}>
          <ModalOverlay
            onClick={onClose}
            type={type}
            name={name}
            id={id}
            handleDalete={handleDalete}
            handleCancel={handleCancel}
            isCancel={isCancel}
          >
            {children}
          </ModalOverlay>
          ,
        </Backdrop>,
        portalElement
      )}
    </Fragment>
  );
};

export default ConfirmPortal;
