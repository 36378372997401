import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyData: {},
};

const CompanySlice = createSlice({
  name: "Company",
  initialState,
  reducers: {
    setCompanyData(state, action) {
      state.companyData = action.payload;
    },
  },
});

export default CompanySlice.reducer;
export const { setCompanyData } = CompanySlice.actions;
