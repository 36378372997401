import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "primereact/button";

import Modal from "@mui/material/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import { Box } from "@mui/material";
import { allTaxApi, taxApi } from "../../../assets/apis";
import Footer from "../../../helper/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmPortal from "../../../uiModals/confirmModel/ConfirmPortal";
import {
  setSiderBarActiveLink,
  setSiderBarActiveList,
} from "../../../redux-store/sidebarActiveList";
import { hasPermission } from "../../../utils/helpers";
export const fetchTaxes = async () => {
  const response = await axios.get(`${allTaxApi}?active=${true}`);
  return response.data;
};

const Tax = () => {
  const { FYear } = useSelector((store) => store.FinancialYear);
  const { loggedUser } = useSelector((store) => store.Auth);
  const dispatch = useDispatch();
  const [openPortal, setOpenPortal] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [taxData, setTaxData] = useState([]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: ["tax"],
    queryFn: async () => {
      const response = await axios.get(taxApi);
      return response.data;
    },
  });

  useEffect(() => {
    if (data?.taxes) {
      setTaxData(data.taxes);
    }
  }, [data]);

  const deleteTax = async (id) => {
    handleClosePortal();
    try {
      const response = await axios.delete(`${taxApi}/${id}`);
      if (response.status === 200) {
        toast.success("Tax deleted successfully");
        queryClient.invalidateQueries("tax");
      } else {
        toast.error("Failed to delete tax. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(`${error.response.data?.message || "Error deleting tax"}`);
      } else if (error.request) {
        toast.error(
          "No response from the server. Please check your internet connection."
        );
      } else {
        toast.error(`An error occurred: ${error.message}`);
      }
    }
  };

  // Function to close the modal
  const handleOpenPortal = (data) => {
    setDeletingItem(data);
    setOpenPortal(true);
  };
  const handleClosePortal = () => {
    setDeletingItem(null);
    setOpenPortal(false);
  };

  const handleInputChangeWithCondition = (e, validationRegex) => {
    const { name, value } = e.target;
    if (value === "" || validationRegex.test(value)) {
      setSearchTerm(value);
    }
  };

  const filteredData = taxData?.filter((item) =>
    Object.values(item).some((val) =>
      val?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    refetch();
  }, [FYear.value]);

  useEffect(() => {
    dispatch(setSiderBarActiveList({ name: "Taxes" }));
    dispatch(setSiderBarActiveLink({ link: "tax" }));
  }, []);

  return (
    <div className="main-panel">
      <div className="content-wrapper  ">
        <div id="first" className="card col-lg-12 p-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="card-title">
              All Taxes{" "}
              <i
                className="fa fa-info-circle text-secondary hover-cursor"
                data-toggle="tooltip"
                title="Tax for Items."
                aria-hidden="true"
                data-original-title="Tax for Items."
              ></i>
            </div>
            {hasPermission(loggedUser, 22) && (
              <button
                className="btn btn-primary px-2 py-1"
                onClick={() => navigate("/add/tax")}
              >
                Add Tax
              </button>
            )}
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label className="form-control-label" htmlFor="name"></label>
                <input
                  type="text"
                  className="form-control "
                  name="name"
                  id="name"
                  placeholder="search"
                  value={searchTerm}
                  onChange={(e) => {
                    handleInputChangeWithCondition(e, /^[\s\S]*$/);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column  align-items-center">
            <DataTable
              value={filteredData}
              style={{ width: "100%" }}
              id="review-table"
              className="mytable"
            >
              <Column
                className="border-bottom border-top"
                header="SR. No."
                body={(rowData, { rowIndex }) => rowIndex + 1}
                style={{minWidth:"4rem"}}
              />

              <Column
                className="border-bottom border-top"
                field="name"
                header="NAME "
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "6rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="rate"
                header="TAX (%) "
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                  padding: "0 5px",
                }}
              />
              <Column
                className="border-bottom border-top"
                field="active"
                body={(data) => (data.active ? "Active" : "Inactive")}
                header="ACTIVE"
                style={{
                  height: "3rem",
                  alignItems: "center",
                  textAlign: "start",
                  minWidth: "9rem",
                }}
              />
              <Column
                className="border-bottom border-top"
                body={(data) => (
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "2rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {hasPermission(loggedUser, 23) && (
                      <i
                        onClick={(e) => navigate(`/edit/tax/${data.id}`)}
                        className="dropdown-item-icon mdi mdi-pen text-primary me-2"
                      />
                    )}
                    {hasPermission(loggedUser, 24) && (
                      <i
                        onClick={(e) => {
                          handleOpenPortal(data);
                        }}
                        className="dropdown-item-icon mdi mdi-delete-forever text-primary me-2"
                      />
                    )}
                  </div>
                )}
                header="ACTIONS"
              />
            </DataTable>
          </div>
        </div>
      </div>
      {openPortal && (
        <ConfirmPortal
          onClose={handleClosePortal}
          type={"Tax"}
          name={deletingItem.name}
          id={deletingItem.id}
          handleDalete={deleteTax}
        ></ConfirmPortal>
      )}
    </div>
  );
};

export default Tax;
