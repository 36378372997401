const userPermissionArray = [
  { value: 0, label: "Grant All" },
  { value: 1, label: "Add Contact" },
  { value: 2, label: "Edit Contact" },
  { value: 3, label: "Delete Contact" },
  { value: 4, label: "Import Contact" },
  { value: 5, label: "Export Contact" },
  { value: 6, label: "Add Lead" },
  { value: 7, label: "Edit Lead" },
  { value: 8, label: "Delete Lead" },
  { value: 9, label: "Import Lead" },
  { value: 10, label: "Export Lead" },
  { value: 11, label: "Add Item" },
  { value: 12, label: "Edit Item" },
  { value: 13, label: "Delete Item" },
  { value: 14, label: "Import Item" },
  { value: 15, label: "Export Item" },
  { value: 16, label: "Add Category" },
  { value: 17, label: "Edit Category" },
  { value: 18, label: "Delete Category" },
  { value: 19, label: "Add Brand" },
  { value: 20, label: "Edit Brand" },
  { value: 21, label: "Delete Brand" },
  { value: 22, label: "Add Tax" },
  { value: 23, label: "Edit Tax" },
  { value: 24, label: "Delete Tax" },
  { value: 25, label: "Add GST HSN" },
  { value: 26, label: "Edit GST HSN" },
  { value: 27, label: "Delete GST HSN" },
  { value: 28, label: "Add GST SAC" },
  { value: 29, label: "Edit GST SAC" },
  { value: 30, label: "Delete GST SAC" },
  { value: 31, label: "Add Sale Invoice" },
  { value: 32, label: "Edit Sale Invoice" },
  { value: 33, label: "Delete Sale Invoice" },
  { value: 34, label: "Add Expense Invoice" },
  { value: 35, label: "Edit Expense Invoice" },
  { value: 36, label: "Delete Expense Invoice" },
  { value: 37, label: "Add Quotation" },
  { value: 38, label: "Edit Quotation" },
  { value: 39, label: "Delete Quotation" },
  { value: 40, label: "Add Payment" },
  { value: 41, label: "Edit Payment" },
  { value: 42, label: "Delete Payment" },
  { value: 43, label: "View Reports" },
  { value: 44, label: "Add User" },
  { value: 45, label: "Edit User" },
  { value: 46, label: "Delete User" },
  { value: 47, label: "Add Bank" },
  { value: 48, label: "Edit Bank" },
  { value: 49, label: "Delete Bank" },
  { value: 50, label: "Edit Print Layout Configurations" },
  { value: 51, label: "Edit Mail Permissions" },
  { value: 52, label: "Edit Company Details" },
  { value: 53, label: "Edit Settings" },
  { value: 54, label: "Dashboard" },
  { value: 55, label: "Add Delivery Challan" },
  { value: 56, label: "Edit Delivery Challan" },
  { value: 57, label: "Delete Delivery Challan" },
  { value: 58, label: "Subscription Details" },
];

export { userPermissionArray };
